<template>
  <div class="login-container">
    <div class="login-content" :class="{ 'login-content-mobile': tabsActiveName === 'mobile' }">
      <div class="login-content-main">
        <div style="display:flex;margin-bottom: 20px;">
          <h4 class="login-content-title">IPPBX管理系统<el-tag size="small" type="warning">V2.0.1</el-tag></h4>
          <div>
            <el-dropdown :show-timeout="70" :hide-timeout="50" trigger="click" @command="onLanguageChange">
            <span class="el-dropdown-link layout-navbars-breadcrumb-user-icon">
              <i class="fa fa-language icon-box"></i>
            </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="zh">简体中文</el-dropdown-item>
                  <el-dropdown-item command="en">English</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
        <div>
          <transition name="el-zoom-in-center">
            <Account v-if="isInstall && !isTabPaneShow"/>
          </transition>
          <transition name="el-fade-in-center">
            <Install v-if="!isInstall"/>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {computed, onMounted} from 'vue'
import Install from './component/install.vue'
import Account from './component/account.vue'
import {
  installCheck
} from '@/http/api.js'
import {useStore} from 'vuex'

export default {
  name: "login",
  components: {Install, Account},
  setup() {
    // let isTabPaneShow = ref(true)
    onMounted(() => {
      checkInstall();
    })
    const checkInstall = () => {
      installCheck().then(res => {
        if (res.code != 200) {
          store.commit('SET_INSTALL')
        }
      });
    }

    const store       = useStore()
    let isTabPaneShow = computed(() => store.state.isTabPaneShow)
    let isInstall     = computed(() => store.state.isInstall)
    return {
      isTabPaneShow,
      isInstall,
    }
  },
  methods: {
    onLanguageChange(command) {
      localStorage.setItem("locale", command);
      this.$i18n.locale = command;
      this.$message({
        type: "success",
        message: this.$t("切换成功")
      });
    },
  }
};
</script>
<style scoped lang="scss">
.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  width: 100%;
  min-height: 100%;
  background: url('/static/img/login2.jpg') no-repeat;
  background-size: 100% 100%;
}

.login-content {
  width: 500px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.99);
  border: 5px solid #d9ecff;
  border-radius: 4px;
  transition: height 0.2s linear;
  z-index: 1;
}

.login-content-main {
  margin: 0 auto;
  width: 80%;
}

.login-content-title {
  margin: auto;
  color: #333;
  font-weight: 500;
  font-size: 22px;
  text-align: center;
  letter-spacing: 4px;
  white-space: nowrap;
}

.icon-box {
  font-size: 18px;
}
</style>
