<template>
  <el-config-provider :locale="locale">
    <slot name="app"></slot>
    <router-view></router-view>
  </el-config-provider>
</template>

<script>
import {computed, ref} from 'vue'
import {
  ElConfigProvider
} from "element-plus";
import zhCn from "element-plus/lib/locale/lang/zh-cn";
import en from "element-plus/lib/locale/lang/en";
import emitter from "@/utils/eventBus";

const language = ref(localStorage.getItem('locale') ?? 'zh')
const locale   = computed(() => (language.value == 'zh' ? zhCn : en))

emitter.on("switchLang", e => {
  language.value = e.lang;
})

export default {
  name: 'App',
  components: {
    [ElConfigProvider.name]: ElConfigProvider,
  },
  setup() {
    return {
      locale
    };
  },
}
</script>

<style>
</style>
