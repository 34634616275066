import {createStore} from 'vuex'

export default createStore({
    state: {
        isCollapse: sessionStorage.getItem('isCollapse') || false,
        isInstall: true,
        isTabPaneShow: false,
        department: false,
        wss_url: sessionStorage.getItem('wssUrl') || '',
        wss_type: sessionStorage.getItem('wssType') || '',
        stat: {
            dep: '',
            account_user: '',
            value1: '',
        },
        tagsViewRoutes: [],
    },
    mutations: {
        SET_COLLAPSE(state) {
            state.isCollapse = !state.isCollapse
            //html5本地储存
            sessionStorage.setItem('isCollapse', JSON.stringify(state.isCollapse))
        },
        SET_COLLAPSER(state) {
            state.isTabPaneShow = !state.isTabPaneShow
            // console.log(state.isTabPaneShow)

            //html5本地储存
            sessionStorage.setItem('isTabPaneShow', JSON.stringify(state.isTabPaneShow))
        },
        SET_INSTALL(state) {
            state.isInstall = !state.isInstall
            // console.log(state.isTabPaneShow)

            //html5本地储存
            sessionStorage.setItem('isInstall', JSON.stringify(state.isInstall))
        },
        SET_WSS(state, url, type) {
            state.wss_url  = url
            state.wss_type = type
            sessionStorage.setItem('wssUrl', url)
            sessionStorage.setItem('wssType', type)
        },
        department_open(state) {
            state.department = true
        },
        department_close(state) {
            state.department = false
        },
        // 设置 TagsView 路由
        getTagsViewRoutes(state, data) {
            state.tagsViewRoutes = data;
        },
    },
    actions: {
        // 设置 TagsView 路由
        async setTagsViewRoutes({commit}, data) {
            commit('getTagsViewRoutes', data);
        },
    },
    modules: {}
})
