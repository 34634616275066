<template>
  <div class="home">
    <el-container class="layout-container">
      <div class="h100">
        <div :class="menuStatusr" v-if="clientWidth >= 1000">
          <a href="#/Home">
            <img src="/static/img/logo-cti-mi.png" alt="" class="img-fluid-left" v-show="!isCollapse">
            <img src="/static/img/logo-cti-ma.png" alt="" class="img-fluid" v-show="isCollapse">
          </a>
        </div>
        <el-aside class="el-aside layout-aside" :class="menuStatus" v-if="clientWidth >= 1000">
          <LayoutNav />
        </el-aside>
        <el-drawer direction="ltr" v-model="isCollapse" class="el-aside layout-aside" title="I am the title"
          :with-header="false" v-else :before-close="handleClose" :class="menuStatus" size="50%">
          <el-scrollbar :height="clientHeight">
            <LayoutNavs />
          </el-scrollbar>
        </el-drawer>
      </div>
      <el-container>
        <el-header class="top-box">
          <LayoutHeader />
        </el-header>
        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
  import {
    toRefs,
    reactive,
    computed,
    onBeforeMount,
    getCurrentInstance,
  } from 'vue'
  import {
    useStore
  } from 'vuex'
  import LayoutHeader from './Components/Header.vue'
  import LayoutNav from './Components/Nav.vue'
  import LayoutNavs from './Components/Navs.vue'
  export default {
    name: 'Home',
    components: {
      LayoutHeader,
      LayoutNav,
      LayoutNavs
    },
    setup() {
      const {
        proxy
      } = getCurrentInstance();
      const state = reactive({
        clientWidth: '',
        clientHeight: '',
      })
      const store = useStore()
      const isCollapse = computed(() => !store.state.isCollapse);
      const menuStatus = computed(() => {
        const isCollapse = store.state.isCollapse;
        if (state.clientWidth <= 1000) {
          if (isCollapse) {
            return ['layout-aside-pc-220'];
          } else {
            return ['layout-aside-pc-220'];
          }
        } else {
          if (isCollapse) {
            return ['layout-aside-pc-64'];
          } else {
            return ['layout-aside-pc-220'];
          }
        }
      });
      const menuStatusr = computed(() => {
        const isCollapse = store.state.isCollapse;
          if (isCollapse) {
            return ['navbar-logor'];
          } else {
            return ['navbar-logo'];
          }
      });
      //关闭抽屉
      let handleClose = () => {
        store.commit('SET_COLLAPSE')
        // store.state.isCollapse = !store.state.isCollapse;
      };
      // 设置菜单导航是否固定（移动端）
      const initMenuFixed = (clientWidth) => {
        state.clientWidth = clientWidth;
      };
      const initMenuFixedd = (clientHeight) => {
        state.clientHeight = clientHeight;
      };
      // 页面加载前
      onBeforeMount(() => {
        initMenuFixed(document.body.clientWidth);
        initMenuFixedd(document.body.clientHeight);
        proxy.mittBus.on('layoutMobileResize', (res) => {
          initMenuFixed(res.clientWidth);
          initMenuFixedd(res.clientHeight);
        });
      });

      return {
        ...toRefs(state),
        menuStatus,
        isCollapse,
        handleClose,
        menuStatusr,
      }
    }
  }
</script>
<style lang="scss" scoped>
  @import "../../theme/home/home.scss";
  .navbar-logo,.navbar-logor{
    background-color: transparent;
  }
</style>
