/**
 * 最顶部 card
 * @returns 返回模拟数据
 */
 export const topCardItemList = [
	{
		title: '中继',
		titleNum: '1',
		titleNum2: '2',
		iconColor: '#F86C6B',
		icon: 'el-icon-s-operation',
		color: '#1e88e5',
	},
	{
		title: '分机',
		titleNum: '10',
		titleNum2: '20',
		iconColor: '#F86C6B',
		icon: 'el-icon-phone',
		color: '#ffb22b',
	},
  {
		title: '呼出规则',
		titleNum: '1',
		titleNum2: '2',
		iconColor: '#92A1F4',
		icon: 'fa fa-arrow-up',
		color: '#7460ee',
	},
  {
		title: '呼入规则',
		titleNum: '10',
		titleNum2: '20',
		iconColor: '#FDC566',
		icon: 'fa fa-arrow-down',
		color: '#fc4b6c',
	},
];

/**
 * 数据总量
 * @returns 返回模拟数据
 */
 export const totalData = [
	{
		title: '呼入',
		titleNum: '100',
	},
	{
		title: '呼入接听',
		titleNum: '10',
	},
  {
		title: '呼入接听比率',
		titleNum: '10%',
	},
  {
		title: '呼出',
		titleNum: '100',
	},
  {
		title: '呼出接听',
		titleNum: '20',
	},
  {
		title: '呼出接听比率',
		titleNum: '20%',
	},
];

/**
 * 事件日志
 * @returns 返回模拟数据
 */
 export const eventLog = [
	{
    icon: 'el-alert__icon el-icon-success',
    date: '2016-05-02',
    name: '王小虎',
    address: '上海市普陀区金沙江路 1518 弄',
  },
  {
    icon: 'el-alert__icon el-icon-info',
    date: '2016-05-04',
    name: '王小虎',
    address: '上海市普陀区金沙江路 1517 弄',
  },
  {
    icon: 'el-alert__icon el-icon-warning',
    date: '2016-05-01',
    name: '王小虎',
    address: '上海市普陀区金沙江路 1519 弄',
  },
  {
    icon: 'el-alert__icon el-icon-error',
    date: '2016-05-03',
    name: '王小虎',
    address: '上海市普陀区金沙江路 1516 弄',
  },
];

/**
 * 字体变化
 * @returns 返回模拟数据
 */
 export const fontVariant = [
	{
    value: '选项1',
    label: '黄金糕',
  },
  {
    value: '选项2',
    label: '双皮奶',
    disabled: true,
  },
  {
    value: '选项3',
    label: '蚵仔煎',
  },
  {
    value: '选项4',
    label: '龙须面',
  },
];

export const restye = ['1:00', 2, 3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24];