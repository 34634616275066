import request from './index.js'

export function installCheck(data={}) {
  return request({
    url: "/api/install/check",
    method: "GET",
    params: data
  })
}

export function installSet(data={}) {
  return request({
    url: "/api/install/set",
    method: "POST",
    params: data
  })
}

export function installStart(data={}) {
  return request({
    url: "/api/install/start",
    method: "POST",
    params: data
  })
}


// 获取验证码
export function captchaAuth(data={}) {
  return request({
    url: "/api/auth/captcha",
    method: "GET",
    params: data
  })
}

export function captchaVerify(data={}) {
  return request({
    url: "/api/auth/verify",
    method: "POST",
    params: data
  })
}

// 获取详情
export function infoAuth(data={}) {
  return request({
    url: "/api/auth/info",
    method: "GET",
    params: data
  })
}

//登录
export function loginAuth(data={}) {
  return request({
    url: "/api/auth/login",
    method: "POST",
    data
  })
}

//退出
export function logoutAuth(data={}) {
  return request({
    url: "/api/auth/logout",
    method: "POST",
    data
  })
}

//修改信息
export function updateAuth(data={}) {
  return request({
    url: "/api/auth/info",
    method: "PUT",
    headers: {
      "Content-Type": "application/json;chart-set:UTF-8"
    },
    data
  })
}
