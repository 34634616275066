<template>
  <el-form :model="loginData" ref="validateFrom" :rules="rules" @keyup.enter="handleSub">
    <el-form-item prop="name">
      <el-input :placeholder="$t('请输入用户名')" autofocus v-model="loginData.name" class="iconr" clearable
                prefix-icon="icon-yonghu">
      </el-input>
    </el-form-item>
    <el-form-item prop="password">
      <el-input :placeholder="$t('请输入密码')" v-model="loginData.password" class="iconr" show-password
                prefix-icon="icon-mima">
      </el-input>
    </el-form-item>
    <el-form-item class="box-remember">
      <el-checkbox v-model="loginData.checked" :label="$t('记住我')" class="checkbox__inner" :true-label='1'
                   :false-label='0'></el-checkbox>
    </el-form-item>
    <el-form-item>
      <el-button @click="subFun" class="login-sub-btn" type="primary" round>
        <span>{{ $t('登录') }}</span>
      </el-button>
    </el-form-item>
  </el-form>
  <el-dialog :title="$t('安全验证')" append-to-body v-model="dialogVisible"
             destroy-on-close custom-class="u-cap">
    <div style="width:100%;display:flex;justify-content: center">
      <Verify
          @changeShow="changeShow"
          mode="fixed"
          :captchaType="captchaType"
          :captchaData="captchaData"
          :imgSize="{ width: '400px', height: '200px' }"
      ></Verify>
    </div>
  </el-dialog>
</template>
<script>
import {
  reactive,
  ref,
  nextTick,
  toRefs,
  watch, computed,
} from 'vue'
import {useI18n} from "vue-i18n";
import {
  captchaAuth,
} from '@/http/api.js'
import Verify from "@/components/verifition/Verify"
import {
  // ElMessageBox,
  ElNotification
} from 'element-plus';

function blackList(loginData) {
  captchaAuth().then(res => {
    if (res.code == 200) {
      loginData.url = res.data.url
    } else {
      ElNotification({
        title: '失败',
        message: res.msg,
        type: 'error',
        duration: 2000,
      });
    }
  })
}

export default ({
  name: 'account',
  components: {Verify},
  setup() {
    const {t}          = useI18n()
    let loginData      = reactive({
      dialogVisible: false,
      name: '',
      password: '',
      code: '',
      url: '',
      checked: 0,
    })
    const validateFrom = ref()
    const rules        = computed(() => {
      const rule = {
        name: [{
          required: true,
          message: t("请输入用户名"),
          trigger: "blur"
        }],
        password: [{
          required: true,
          message: t("请输入密码"),
          trigger: "blur"
        }],
        code: [{
          required: true,
          message: t("请输入验证码"),
          trigger: "blur"
        }],
      }
      return rule;
    })
    nextTick(() => {
      blackList(loginData);
      if (localStorage.getItem('checked') != null) {
        loginData.checked  = Number(localStorage.getItem('checked'));
        loginData.name     = localStorage.getItem('username');
        loginData.password = localStorage.getItem('password');
      }
    })
    watch(() => loginData.checked, (newVal) => {
      loginData.checked = newVal;
    })
    const captchaType = ref('')
    const captchaData = ref('')

    function clickChange() {
      blackList(loginData)
    }

    const handleSub = (e) => {
      var keyCode = window.event ? e.keyCode : e.which;
      if (keyCode == 13) {
        subFun();
      }
    }

    let subFun = () => {
      captchaType.value = "blockPuzzle"
      validateFrom.value.validate((valid) => {
        if (valid) {
          loginData.dialogVisible = true;
          const params            = {
            phone: loginData.name,
            password: loginData.password,
            checked: loginData.checked,
          }
          captchaData.value       = params
        }
      });
    }

    const changeShow = (msg) => {
      loginData.dialogVisible = msg;
    }

    return {
      loginData,
      subFun,
      handleSub,
      changeShow,
      ...toRefs(loginData),
      clickChange,
      validateFrom,
      captchaType,
      captchaData,
      rules
    }

  },
})
</script>
<style scoped>
.iconr :deep(.el-input__icon) {
  font-style: normal !important;
}

.login-content-code {
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
}

.el-form-item {
  margin-bottom: 20px;
}

.login-content-code-img {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: #ffffff;
  border: 1px solid rgb(220, 223, 230);
  color: #333;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 5px;
  text-indent: 5px;
  text-align: center;
  cursor: pointer;
  transition: all ease 0.2s;
  border-radius: 4px;
  user-select: none;

  &:hover {
    border-color: #c0c4cc;
    transition: all ease 0.2s;
  }
}

.login-content-main .login-sub-btn {
  width: 100%;
  letter-spacing: 2px;
  font-weight: 300;
}

.box-remember :deep(.el-form-item__content) {
  display: flex;
  justify-content: space-between;
}

.checkbox__inner:after {
  height: 14px;
  left: 7px;
  top: 0;
}
</style>
<style>
.u-cap {
  width: 430px !important;
}
</style>

