const zh = {
    //PUBLIC
    清空: '清空',
    成功: '成功',
    失败: '失败',
    提示: '提示',
    警告: '警告',
    错误: '错误',
    状态: '状态',
    姓名: '姓名',
    地址: '地址',
    操作: '操作',
    确定: '确定',
    无数据: '无数据',
    取消: '取消',
    保存: '保存',
    新增: '新增',
    添加成功: '添加成功',
    删除成功: '删除成功',
    编辑成功: '编辑成功',
    解绑成功: '解绑成功',
    当天: '当天',
    昨天: '昨天',
    本周: '本周',
    上周: '上周',
    本月: '本月',
    上月: '上月',
    退出中: '退出中',
    此操作将退出登录是否继续: '此操作将退出登录, 是否继续?',
    退出成功: '退出成功！',
    端口: '端口',
    更新: '更新',
    安全验证: '安全验证',
    加载中: '加载中...',

    数据库: '数据库',
    数据库地址: '数据库地址',
    数据库用户名: '数据库用户名',
    数据库密码: '数据库密码',
    管理员: '管理员',
    管理员用户名: '管理员用户名',
    管理员密码: '管理员密码',
    管理员确认密码: '管理员确认密码',
    请输入数据库地址: '请输入数据库地址',
    请输入Redis地址: '请输入Redis地址',
    请输入数据库用户名: '请输入数据库用户名',
    请输入数据库密码: '请输入数据库密码',
    请输入Redis密码: '请输入Redis密码',
    配置文件已写入即将开始安装: '配置文件已写入，即将开始安装...',
    配置文件已写入即将开始更新: '配置文件已写入，即将开始更新...',
    安装成功即将跳转至登录页: '安装成功！即将跳转至登录页！',
    更新成功即将跳转至登录页: '更新成功！即将跳转至登录页！',
    安装: '安装',
    请选择安装类型: '请选择安装类型',
    安装类型: '安装类型',
    初始化: '初始化',
    连接地址: '连接地址',
    请输入WebSocket地址: '请输入WebSocket地址',
    WebSocket连接失败: 'WebSocket连接失败',
    请求错误: '请求错误',

    登录成功: '登录成功',
    请输入手机号或邮箱: '请输入手机号或邮箱',
    获取验证码: '获取验证码',
    重置: '重置',
    保存截图: '保存截图',
    验证失败: '验证失败',
    向右滑动完成验证: '向右滑动完成验证',

    首页: '首页',
    下拉刷新: '下拉刷新',
    上拉加载: '上拉加载',
    切换成功: '切换成功',
    语言: '语言',
    默认: '默认',
    大号: '大号',
    小型: '小型',
    超小: '超小',
    分机: '分机',
    分机设置: '分机设置',
    呼叫控制: '呼叫控制',
    呼叫功能: '呼叫功能',
    群组: '群组',
    分组: '分组',
    分组设置: '分组设置',
    队列: '队列',
    队列设置: '队列设置',
    电话会议: '电话会议',
    部门: '部门',
    部门设置: '部门设置',
    中继: '中继',
    中继设置: '中继设置',
    总机: '总机',
    IVR总机: 'IVR 总机',
    数据中心: '数据中心',
    通话记录: '通话记录',
    通话统计: '通话统计',
    安全: '安全',
    IP黑名单: 'IP黑名单',
    黑名单号码: '黑名单号码',
    安全设置: '安全设置',
    系统参数: '系统参数',
    设置: '设置',
    功能设置: '功能设置',
    菜单搜索: '菜单搜索',
    个人设置: '个人设置',
    退出登录: '退出登录',
    请输入用户名: '请输入用户名',
    请输入密码: '请输入密码',
    请输入新密码: '请输入新密码',
    请确认密码: '请确认密码',
    请输入验证码: '请输入验证码',
    记住我: '记住我',
    忘记密码: '忘记密码',
    登录: '登录',
    呼入规则: '呼入规则',
    呼出规则: '呼出规则',
    删除前置位数: '删除前置位数',
    前置号码: '前置号码',
    匹配模式不能为空: '匹配模式不能为空',
    删除前置位数只能设置数字: '删除前置位数只能设置数字',
    前置号码格式错误: '前置号码格式错误',

    今日通话: '今日通话',
    呼入接听: '呼入接听',
    呼入接听比率: '呼入接听比率',
    呼出接听: '呼出接听',
    呼出接听比率: '呼出接听比率',
    事件日志: '事件日志',
    系统监控: '系统监控',
    CPU使用率: 'CPU使用率',
    内存使用率: '内存使用率',
    磁盘使用率: '磁盘使用率',
    内存: '内存',
    磁盘: '磁盘',

    分机号码: '分机号码',
    分机号或名称: '分机号或名称',
    分机号: '分机',
    分机密码: '分机密码',
    名称: '名称',
    职务: '职务',
    禁止外呼: '禁止外呼',
    禁止: '禁止',
    局内: '局内',
    本地: '本地',
    全国: '全国',
    国内: '国内',
    国际: '国际',
    录音: '录音',
    类型: '类型',
    国际权限: '国际权限',
    普通分机: '普通分机',
    队列分机: '队列分机',
    创建数量: '创建数量',
    请选择要删除的: '请选择要删除的',
    添加: '添加',
    批量添加: '批量添加',
    批量添加分机: '批量添加分机',
    编辑: '编辑',
    删除: '删除',
    导入: '导入',
    导出: '导出',
    打包录音: '打包录音',
    修改: '修改',
    查询: '查询',
    新增分机: '新增分机',
    请选择权限: '请选择权限',
    请选择中继: '请选择中继',
    请输入分机号: '请输入分机号',
    分机号只允许添加数字: '分机号只允许添加数字',
    请输入分机密码: '请输入分机密码',
    分机密码限制6到8位且必须包含大小写字母和数字: '限制6-8位且必须包含大小写字母和数字',
    请选择类型: '请选择类型',
    请输入分机数量: '请输入分机数量',
    你确定要删除该分机吗: '你确定要删除该分机吗？',
    请选择要删除的分机: '请选择要删除的分机',
    等待时间s: '等待时间(s)',
    编辑分机: '编辑分机',
    密码为小于六位的数字: '密码为小于六位的数字',
    请选择留言提示音: '请选择留言提示音',
    请选择时间条件: '请选择时间条件',
    导入分机: '导入分机',
    下载模版: '下载模版',
    下载文件模板: '下载文件模板',

    新增部门: '新增部门',
    编辑部门: '编辑部门',
    部门编辑: '部门编辑',
    部门删除: '部门删除',
    部门新增: '部门新增',
    归属: '归属',
    网关: '网关',
    搜索分机: '搜索分机',
    请选择部门: '请选择部门',
    请选择网关: '请选择网关',
    移除: '移除',
    分机成员: '分机成员',
    可用分机: '可用分机',
    查找部门: '查找部门',
    你确定要删除该部门吗: '你确定要删除该部门吗？',
    你确定要移除该分机吗: '你确定要移除该分机吗？',
    默认为一级部门: '默认为一级部门',
    请选择呼叫权限: '请选择呼叫权限',
    请输入名称: '请输入名称',

    新增分组: '新增分组',
    编辑分组: '编辑分组',
    分组新增: '分组新增',
    分组编辑: '分组编辑',
    分组名称或号码: '分组名称或号码',
    号码: '号码',
    成员数量: '成员数量',
    代答组: '代答组',
    同振: '同振',
    顺振: '顺振',
    请输入分组名称: '请输入分组名称',
    请输入号码: '请输入号码',
    组号码只允许添加数字: '组号码只允许添加数字',
    请选择分组类型: '请选择分组类型',
    你确定要删除该分组吗: '你确定要删除该分组吗？',
    一天: '一天',
    两天: '两天',
    三天: '三天',
    四天: '四天',
    五天: '五天',
    六天: '六天',
    七天: '七天',
    三十天: '三十天',
    转接: '转接',

    新增队列: '新增队列',
    编辑队列: '编辑队列',
    队列新增: '队列新增',
    队列编辑: '队列编辑',
    基本信息: '基本信息',
    队列成员: '队列成员',
    排队人数: '排队人数',
    提示音间隔: '提示音间隔',
    失败提示音: '失败提示音',
    等待提示音: '等待提示音',
    提示音播放顺序: '提示音播放顺序',
    前: '前',
    后: '后',
    等待超时时间: '等待超时时间',
    队列号码只允许添加数字: '队列号码只允许添加数字',
    请输入最大排队人数: '请输入最大排队人数',
    请输入等待间隔: '请输入等待间隔',
    请选择失败音: '请选择失败音',
    请选择等待音: '请选择等待音',
    请选择播放顺序: '请选择播放顺序',
    等待超时时间s: '等待超时时间(s)',
    提示音间隔s: '提示音间隔(s)',
    你确定要删除该队列吗: '你确定要删除该队列吗？',
    队列名称或号码: '队列名称或号码',
    提示音状态: '提示音状态',

    中继新增: '中继新增',
    新增中继: '新增中继',
    编辑中继: '编辑中继',
    导入中继: '导入中继',
    中继名称: '中继名称',
    基本: '基本',
    编码: '编码',
    支持编码: '支持编码',
    高级: '高级',
    改编来电号码: '改编来电号码',
    中继类型: '中继类型',
    注册中继: '注册中继',
    点对点中继: '点对点中继',
    账号中继: '账号中继',
    中继号码: '中继号码',
    请输入中继号码: '请输入中继号码',
    号码名称: '号码名称',
    系统状态: '系统状态',
    协议: '协议',
    传输协议: '传输协议',
    域名IP地址: '域名/IP地址',
    主域服务器: '主域服务器',
    用户名: '用户名',
    密码: '密码',
    认证名称: '认证名称',
    最大并发数: '最大并发数',
    VoIP设置: 'VoIP设置',
    心跳检测: '心跳检测',
    DTMF模式: 'DTMF模式',
    DID设置: 'DID设置',
    DID号码: 'DID号码',
    DNIS名称: 'DNIS名称',
    配置相应的模式来重新格式化呼入和呼出的号码: '配置相应的模式来重新格式化呼入和呼出的号码',
    主叫号码变换: '主叫号码变换',
    被叫号码变换: '被叫号码变换',
    原模式: '原模式',
    模式: '模式',
    长度3到6位的数字: '长度3-6位的数字',
    请选择中继类型: '请选择中继类型',
    请选择传输协议: '请选择传输协议',
    请输入端口号: '请输入端口号',
    请输入认证名称: '请输入认证名称',
    请选择最大并发数: '请选择最大并发数',
    请输入域名ip: '请输入域名ip',
    请填写被叫模式: '请填写被叫模式',
    请填写主叫模式: '请填写主叫模式',
    请填写号码变换模式: '请填写号码变换模式',
    代理服务器: '代理服务器',
    你确定要删除该中继吗: '你确定要删除该中继吗？',
    请选择要删除的中继: '请选择要删除的中继？',
    域名Ip: '域名Ip',
    特殊字符定义如下: '特殊字符定义如下',
    X代表从0到9的任意数字: 'X 代表从 0 到 9 的任意数字',
    Z代表从1到9的任意数字: 'Z 代表从 1 到 9 的任意数字',
    N代表从2到9的任意数字: 'N 代表从 2 到9 的任意数字',
    代表括内任数字例如在此例中代表号: '代表括内任⼀数字，例如在此例⼦中代表号',
    码: '码',
    通配符代表匹配到多个数字例如9011: '通配符“.” 代表匹配 1 到多个数字。例如：“9011.”',
    代表匹配任何以9011开头的号码但不包含9011: '代表匹配任何以 9011 开头的号码，但不包含 9011',
    通配符代表匹配0到多个数字例如9011: '通配符“!” 代表匹配0到多个数字。例如：“9011!”',
    代表匹配任何以9011开头的号码包含9011: '代表匹配任何以 9011 开头的号码，包含 9011',
    请选择中继或中继组: '请选择中继或中继组',
    号码变换编辑成功: '号码变换编辑成功',
    全局DOD: '全局DOD',
    导入DOD: '导入DOD',
    配置相应的模式来重新格式化呼入的号码: '配置相应的模式来重新格式化呼入的号码。',
    配置相应的模式来重新格式化呼出的号码: '配置相应的模式来重新格式化呼出的号码。',
    新增DOD绑定分机: '新增DOD绑定分机',
    编辑DOD绑定分机: '编辑DOD绑定分机',
    导入DOD绑定分机: '导入DOD绑定分机',
    请输入DOD号码: '请输入DOD号码',
    请选择绑定分机: '请选择绑定分机',
    来电号码变换: '来电号码变换',
    对端提供账号密码等认证信息需要向对端设备进行注册的中继类型通常用于通过互联网对接时己端没有静态公网IP需要向对端设备进行注册或对端设备强制要求注册的场景下使用: '对端提供账号、密码等认证信息，需要向对端设备进行注册的中继类型。通常用于通过互联网对接时己端没有静态公网 IP 需要向对端设备进行注册。或对端设备强制要求注册的场景下使用。',
    两端通过IP地址对接无需注册认证通常用于企业内部局域网环境对接本地网关对接或互联网固定IP地址对接的场景: '两端通过 IP 地址对接，无需注册认证。通常用于企业内部局域网环境对接、本地网关对接或互联网固定 IP 地址对接的场景。',
    账号中继一般用于对接PBX与其他设备你可以在IPPBX创建一个账号其他设备通过注册该账号连接到IPPBX: '账号中继一般用于对接 PBX 与其他设备。你可以在 IPPBX 创建一个账号，其他设备通过注册该账号连接到 IPPBX。',

    中继组设置: '中继组设置',
    中继组名称: '中继组名称',
    备注: '备注',
    新增中继组: '新增中继组',
    编辑中继组: '编辑中继组',
    中继组成员: '中继组成员',
    中继成员: '中继成员',
    成员中继: '成员中继',
    呼叫分配类型: '呼叫分配类型',
    优先级: '优先级',
    权重: '权重',
    请输入中继组名称: '请输入中继组名称',
    请选择呼叫分配类型: '请选择呼叫分配类型',
    你确定要删除该中继组吗: '你确定要删除该中继组吗?',
    DOD号码: 'DOD号码',
    绑定分机: '绑定分机',
    请选择: '请选择',

    替换模式: '替换模式',
    替换号码: '替换号码',


    新增呼入路由: '新增呼入路由',
    编辑呼入路由: '编辑呼入路由',
    路由名称: '路由名称',
    DID匹配模式: 'DID匹配模式',
    来电匹配模式: '来电匹配模式',
    启用时间条件: '启用时间条件',
    时间条件: '时间条件',
    目的地: '目的地',
    挂机: '挂机',
    你确定要删除该路由吗: '你确定要删除该路由吗？',
    请选择要删除的路由: '请选择要删除的路由',
    请选择目的类型: '请选择目的类型',
    请选择目的号码: '请选择目的号码',
    搜索中继: '搜索中继',
    主叫号码匹配模式: '主叫号码匹配模式',
    请选择时间条件可多选: '请选择时间条件（可多选)',
    DID号码范围匹配到分机范围: 'DID号码范围匹配到分机范围',
    DID号码规则匹配到分机: 'DID号码规则匹配到分机',
    DID号码匹配到指定分机: 'DID号码匹配到指定分机',
    DID号码规则: 'DID号码规则',
    匹配分机: '匹配分机',
    DID范围: 'DID范围',
    分机范围: '分机范围',
    请选择DID匹配模式: '请选择DID匹配模式',
    请输入DID范围: '请输入DID范围',
    请选择匹配分机: '请选择匹配分机',
    新增DID匹配分机: '新增DID匹配分机',
    编辑DID匹配分机: '编辑DID匹配分机',
    导入DID匹配分机: '导入DID匹配分机',
    请输入DID号码: '请输入DID号码',
    请选择要删除的数据: '请选择要删除的数据',
    上传成功: '上传成功',
    你确定要删除该数据吗: '你确定要删除该数据吗？',


    新增呼出路由: '新增呼出路由',
    编辑呼出路由: '编辑呼出路由',
    导入呼出路由: '导入呼出路由',
    拨号模式: '拨号模式',
    呼出模式: '呼出模式',
    删除前缀位数: '删除前缀位数',
    前置: '前置',
    循环抓取: '循环抓取',
    请输入外呼权重: '请输入外呼权重',
    请选择外呼权限: '请选择外呼权限',
    请选择目标类型: '请选择目标类型',
    请选择状态: '请选择状态',
    外呼优先级: '外呼优先级',
    请输入优先级: '请输入优先级',

    总机新增: '总机新增',
    新增总机: '新增总机',
    总机编辑: '总机编辑',
    编辑总机: '编辑总机',
    请输入总机名: '请输入总机名',
    总机名称: '总机名称',
    参数设置: '参数设置',
    语音选项: '语音选项',
    按键设置: '按键设置',
    普通总机: '普通总机',
    直拨总机: '直拨总机',
    直拨分机: '直拨分机',
    按键等待: '按键等待(秒)',
    按键间隔: '按键间隔(秒)',
    按键失败: '按键失败(次)',
    超时次数: '超时次数(次)',
    你确定要删除该总机吗: '你确定要删除该总机吗？',
    请输入按键等待时间: '请输入按键等待时间',
    按键等待时间格式错误: '按键等待时间格式错误',
    请输入按键间隔时间: '请输入按键间隔时间',
    按键间隔时间格式错误: '按键间隔时间格式错误',
    请输入按键失败次数: '请输入按键失败次数',
    按键失败次数格式错误: '按键失败次数格式错误',
    请输入超时次数: '请输入超时次数',
    超时次数格式错误: '超时次数格式错误',
    请输入内容: '请输入内容',
    转其他号码: '转其他号码',
    转Ivr: '转Ivr',
    按键格式错误: '按键格式错误',
    请填写按键设置: '请填写按键设置',
    请完整填写按键设置: '请完整填写按键设置',
    欢迎语: '欢迎语',
    菜单提示音: '菜单提示音',
    按键: '按键',

    重听: '重听',
    菜单: '菜单',
    转分机: '转分机',
    长提示音: '长提示音',
    短提示音: '短提示音',
    无效提示音: '无效提示音',
    退出提示音: '退出提示音',
    主叫回拨: '主叫回拨',
    主叫追踪: '主叫追踪',
    请选择主叫追踪时间: '请选择主叫追踪时间',
    目的: '目的',
    目的号码: '目的号码',


    通话记录和录音: '通话记录和录音',
    筛选: '筛选',
    时间: '时间',
    开始时间: '开始时间',
    结束时间: '结束时间',
    通话状态: '通话状态',
    呼入: '呼入',
    呼出: '呼出',
    拨通: '拨通',
    未拨通: '未拨通',
    主叫: '主叫',
    被叫: '被叫',
    主叫号码: '主叫号码',
    被叫号码: '被叫号码',
    最大通话时长: '最大通话时长',
    最小通话时长: '最小通话时长',
    下载通话记录: '下载通话记录',
    下载录音: '下载录音',
    呼叫开始时间: '呼叫开始时间',
    通话时长: '通话时长',
    呼叫类型: '呼叫类型',
    呼叫方向: '呼叫方向',
    费用: '费用',
    录音文件选项: '录音文件选项',
    通话方向: '通话方向',
    平均通话时长秒: '平均通话时长',
    平均时长: '平均时长',
    次数: '次数',
    次: '次',
    时长秒: '时长(秒)',
    呼入时长: '呼入时长',
    呼出时长: '呼出时长',
    呼入次数: '呼入次数',
    呼出次数: '呼出次数',
    返回上一级部门: '返回上一级部门',


    双击部门所在的行进入部门分机统计: '双击部门所在的行进入部门分机统计',
    纯数据保存: '纯数据保存',
    本地保存: '本地保存',
    列表: '列表',
    图表: '图表',
    总次数: '总次数',
    通话次数: '通话次数',
    接听比率: '接听比率',
    平均通话时长: '平均通话时长',

    信息: '信息',
    IP地址或号码: 'IP地址或号码',
    请填写IP地址或号码信息: '请填写IP地址或号码信息',
    请选择要删除的黑名单: '请选择要删除的黑名单',
    新增黑名单: '新增黑名单',
    编辑黑名单: '编辑黑名单',
    描述: '描述',
    你确定要删除该ip吗: '你确定要删除该ip吗？',
    请选择要删除的ip: '请选择要删除的ip',
    ip格式错误: 'ip格式错误',
    请输入ip地址: '请输入ip地址',
    请输入描述: '请输入描述',

    安全组: '安全组',
    新增安全组: '新增安全组',
    编辑安全组: '编辑安全组',
    请输入安全组名称: '请输入安全组名称',
    请输入备注信息: '请输入备注信息',
    安全组名称: '安全组名称',
    端口范围: '端口范围',
    规则: '规则',
    安全组成员: '安全组成员',
    应用于分机: '应用于分机',
    应用分机: '应用分机',
    新增安全组规则: '新增安全组规则',
    编辑安全组规则: '编辑安全组规则',
    允许: '允许',
    请输入IP地址: '请输入IP地址',
    请定义优先级: '请定义优先级',

    你可以更改电话系统登陆用户名和密码需要在管理控制台中的安全设置选项里更改: '你可以更改电话系统登陆用户名和密码，需要在管理控制台中的安全设置选项里更改',
    旧密码: '旧密码',
    新密码: '新密码',
    确认密码: '确认密码',
    请输入旧密码: '请输入旧密码',
    八到12位包含大小写字母和数字: '8-12位，包含大小写字母和数字',


    会议新增: '会议新增',
    会议编辑: '会议编辑',
    会议号或名称: '会议号或名称',
    会议: '会议',
    容量: '容量',
    开放型: '开放型',
    密码型: '密码型',
    创建时间: '创建时间',
    你确定要删除该会议吗: '你确定要删除该会议吗？',
    请输入最大成员数: '请输入最大成员数',
    请选择语言: '请选择语言',
    会议号只允许添加数字: '只允许添加数字',
    长度三到六位的数字: '长度3到6位的数字',

    假期: '假期',
    月份: '月份',
    星期: '星期',
    所有日期: '所有日期',
    开始日期: '开始日期',
    结束日期: '结束日期',
    新增时间条件: '新增时间条件',
    编辑时间条件: '编辑时间条件',
    全选: '全选',
    反选: '反选',
    星期日: '星期日',
    星期一: '星期一',
    星期二: '星期二',
    星期三: '星期三',
    星期四: '星期四',
    星期五: '星期五',
    星期六: '星期六',
    一月: '一月',
    二月: '二月',
    三月: '三月',
    四月: '四月',
    五月: '五月',
    六月: '六月',
    七月: '七月',
    八月: '八月',
    九月: '九月',
    十月: '十月',
    十一月: '十一月',
    十二月: '十二月',
    请选择要删除的假期: '请选择要删除的假期',
    你确定要删除该假期吗: '你确定要删除该假期吗？',
    你确定要删除该时间吗: '你确定要删除该时间吗？',
    请选择要删除的时间: '请选择要删除的时间？',
    请选择假期类型: '请选择假期类型',
    请填写开始时间: '请填写开始时间',
    请填写结束时间: '请填写结束时间',
    请选择星期: '请选择星期',

    功能码: '功能码',
    功能码输入超时时间: '功能码输入超时时间',
    一键录音: '一键录音',
    全局录音切换: '全局录音切换',
    分机的语音信箱: "分机的语音信箱",
    收听语音信箱: '收听语音信箱',
    分机语音信箱: '分机语音信箱',
    语音信箱菜单: '语音信箱菜单',
    通话转接: '通话转接',
    盲转接: '盲转接',
    指定转接: '指定转接',
    指定转接时长: '指定转接时长(s)',
    转移按键超时时间: '转移按键超时时间(s)',
    截答: '截答',
    同组截答: '同组截答',
    指定截答: '指定截答',
    呼叫转移: '呼叫转移',
    无条件转移: '无条件转移',
    遇忙转移: '遇忙转移',
    无应答转移: '无应答转移',
    免打扰: '免打扰',
    启用免打扰: '启用免打扰',
    取消免打扰: '取消免打扰',
    提示音: '提示音',
    提示音选项: '提示音选项',
    等待音乐: '等待音乐',
    播放呼叫转移提示音: '播放呼叫转移提示音',
    呼叫转移等待音乐: '呼叫转移等待音乐',
    被叫号码无效提示音: '被叫号码无效提示音',
    中继忙提示音: '中继忙提示音',
    呼叫失败提示音: '呼叫失败提示音',
    事件中心提示音: '事件中心提示音',
    一键录音开始提示音: '一键录音开始提示音',
    一键录音结束提示音: '一键录音结束提示音',
    新增等待音乐列表: '新增等待音乐列表',
    播放顺序: '播放顺序',
    随机: '随机',
    按英文字母: '按英文字母',
    选择等待音乐列表: '选择等待音乐列表',
    上传新的等待音乐: '上传新的等待音乐',
    浏览: '浏览',
    上传: '上传',
    自定义提示音: '自定义提示音',
    录制新提示音: '录制新提示音',
    请设置功能码: '请设置功能码',

    权限: '权限',
    用户管理: '用户管理',
    新增用户: '新增用户',
    权限管理: '权限管理',
    新增权限: '新增权限',
    权限名称: '权限名称',
    系统设置: '系统设置',
    授权管理: '授权管理',
    配置管理: '配置管理',
    导入授权文件: '导入授权文件',
    上传失败: '上传失败',
    总并发: '总并发',
    总caps: '总Caps',

    启用: '启用',
    禁用: '禁用',
    注册: '注册',
    未注册: '未注册',
    基本设置: '基本设置',
    高级设置: '高级设置',
    注册状态: '注册状态',
    出局外显: '出局外显',
    无条件转接: '无条件转接',
    无应答转接: '无应答转接',
    遇忙转接: '遇忙转接',
    等待时间: '等待时间',
    转接号码: '转接号码',
    呼转外显: '呼转外显',
    语音信箱: '语音信箱',
    留言提示音: '留言提示音',
    部门成员: '部门成员',
    呼叫权限: '呼叫权限',
    成员分机: '成员分机',
    分组成员: '分组成员',
    试听: '试听',
    域名ip: '域名/ip',
    并发数: '并发数',
    可用编码: '可用编码',
    已选编码: '已选编码',
    呼入路由: '呼入路由',
    匹配模式: '匹配模式',
    目的类型: '目的类型',
    正常路由: '正常路由',
    DID路由: 'DID路由',
    可用中继: '可用中继',
    已选中继: '已选中继',
    呼出路由: '呼出路由',
    目标类型: '目标类型',
    中继组: '中继组',
    外呼权限: '外呼权限',
    外呼权重: '外呼权重',
    号码变换: '号码变换',
    呼出路由成员: '呼出路由成员',
    更新时间: '更新时间',
    IP地址: 'IP地址',
    会议室: '会议室',
    新增假期: '新增假期',
    编辑假期: '编辑假期',
    假期类型: '假期类型',
    日期: '日期',
    说明: '说明',
    休息日: '休息日',
    工作日: '工作日',
    节日: '节日',
    节假日: '节假日',
    请选择日期: '请选择日期',

    添加提示音: '添加提示音',
    提示音名称: '提示音名称',
    提示音上传: '提示音上传',
    上传文件: '上传文件',
    媒体名称: '媒体名称',
    你确定要删除该媒体吗: '你确定要删除该媒体吗？',
    请选择要删除的媒体: '请选择要删除的媒体',
    限制上传wav文件8k采样率: '限制上传wav文件8k采样率',
    文件大小不能超过20M: '文件大小不能超过20M',
    录制: '录制',

    文本: '文本',
    转语音: '转语音',

    日志管理: '日志管理',
    操作日志: '操作日志',
    操作对象: '操作对象',
    操作类型: '操作类型',
    操作时间: '操作时间',
    对象: '对象',
    内容: '内容',
    文件名称: '文件名称',
    文件大小: '文件大小',
    服务类型: '服务类型',
    下载: '下载',
    系统: '系统',
    服务日志: '服务日志',
    全部: '全部',
    媒体: '媒体',
    其它: '其它',
    黑名单: '黑名单',

    性能设置: '性能设置',
    网络设置: '网络设置',
    修改当前设置会重启语音服务并中断当前所有通话: '修改当前设置会重启语音服务并中断当前所有通话',
    最大通话时长秒: '最大通话时长秒（秒）',
    十秒内最大注册尝试次数: '10秒内最大注册尝试次数',
    锁定时长秒: '锁定时长（秒）',
    起始值: '起始值',
    结束值: '结束值',
    RTP地址: 'RTP Ip',
    SIP地址: 'SIP Ip',
    RTP端口范围: 'RTP端口范围',
    SIP对接端口: 'SIP对接端口',
    SIP认证端口: 'SIP认证端口',
    SIP协议内网IP: 'SIP协议内网IP',
    SIP协议公网IP: 'SIP协议公网IP',
    至: '至',
    查看锁定设备: '查看锁定设备',
    语音服务重启: '语音服务重启',
    语音服务重启完成: '语音服务重启完成',
    请选择模式: '请选择模式',
    每秒建立呼叫数量上限: '每秒建立呼叫数量上限',
    手动指定: '手动指定',
    自动获取: '自动获取',

    查看锁定地址: '查看锁定地址',
    锁定地址列表: '锁定地址列表',
    锁定地址明细: '锁定地址明细',
    你确定要清空数据吗: '你确定要清空数据吗？',
    清空成功: '清空成功',

    存储管理: '存储管理',
    数据设置: '数据设置',
    每月一号凌晨2点配置自动生效: '每月一号凌晨2点配置自动生效',
    任务查看: '任务查看',
    话单保存时间月: '话单保存时间（月）',
    录音保存时间月: '录音保存时间（月）',
    话单保存时间: '话单保存时间',
    录音保存时间: '录音保存时间',
    话单保存时间生效: '话单保存时间生效',
    录音保存时间生效: '录音保存时间生效',
    任务清单: '任务清单',
    任务状态: '任务状态',
    执行时间: '执行时间',
    任务类型: '任务类型',
    当前规则: '当前规则',
    个月: '个月',
    已执行: '已执行',
    未执行: '未执行',
    新增任务: '新增任务',
    请选择任务类型: '请选择任务类型',
    请定义任务时间: '请定义任务时间',
    限制为当月内: '限制为当月内',
    至少为当前时间的10分钟以后: '至少为当前时间的10分钟以后',

    日志设置: '日志设置',
    时间范围: '时间范围',
    录音保存时间小于等于话单保存时间: '录音保存时间小于等于话单保存时间',
    FreeSWITCH单个文件大小: 'FreeSWITCH单个文件大小（M）',
    FreeSWITCH文件个数: 'FreeSWITCH文件个数',
    Core最大存储空间: 'Core最大存储空间（M）',
    Core保存天数: 'Core保存天数',

    会议2: '会议',
    会议管理: '会议管理',
    全部会议: '全部会议',
    预定义会议: '预定义会议',
    立即会议: '立即会议',
    全部成员: '全部成员',
    会议名称或号码: '会议名称或号码',
    会议控制: '会议控制',
    进行中: '进行中',
    未开始: '未开始',
    会议成员: '会议成员',
    已入会: '已入会',
    未入会: '未入会',
    全员静音: '全员静音',
    解除全员静音: '解除全员静音',
    加入会议: '加入会议',
    结束会议: '结束会议',
    开始会议: '开始会议',
    静音: '静音',
    邀请: '邀请',
    在线: '在线',
    离线: '离线',
    添加预定义会议: '添加预定义会议',
    已选择: '已选择',
    请选择要删除的会议: '请选择要删除的会议',
    你确定要删除该会议2吗: '你确定要删除该会议吗？',
    请选择要删除的联系人: '请选择要删除的联系人',
    你确定要删除该联系人吗: '你确定要删除该联系人吗？',
    主持人: '主持人',
    会议名称: '会议名称',
    会议号码 : '会议号码',
    备注信息 : '备注信息',
    请选择主持人: '请选择主持人',
    请选择参会成员: '请选择参会成员',
    号码只允许添加数字: '号码只允许添加数字',
    联系人新增: '联系人新增',
    联系人编辑: '联系人编辑',
    未分配分机: '未分配分机',
    自建联系人: '自建联系人',
    预定义: '预定义',
    自定义: '自定义',
    即时: '即时',
    邀请成员: '邀请成员',
    联系人: '联系人',
    请选择号码: '请选择号码',
    请选择成员: '请选择成员',
    添加成员成功: '添加成员成功',
    移除成员成功: '移除成员成功',
    操作成功: '操作成功',
    开启成功: '开启成功',
    挂断成功: '挂断成功',
    会议未开始: '会议未开始',
    会议已结束: '会议已结束',
}
export default zh
