<template>
  <el-breadcrumb separator="/" class="layout-navbars-breadcrumb-hide" v-if="clientWidth >= 1000">
    <el-breadcrumb-item v-for="(item, index) in list" :key="index">
      <router-link :to="{path:item.path}">{{$t(item.meta.title)}}
      </router-link>
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>
<script>
  import {
    reactive,
    toRefs,
    onMounted,
    watch,
    getCurrentInstance,
    onBeforeMount
  } from 'vue';
  import {
    useRoute
  } from 'vue-router';
  export default {
    name: 'crumbs',
    setup() {
      const route = useRoute();
      const {
        proxy
      } = getCurrentInstance();
      const state = reactive({
        list: [],
        clientWidth: '',
      });
      const initMenuFixed = (clientWidth) => {
        state.clientWidth = clientWidth;
      };
      // 页面加载前
      onBeforeMount(() => {
        initMenuFixed(document.body.clientWidth);
        proxy.mittBus.on('layoutMobileResize', (res) => {
          initMenuFixed(res.clientWidth);
        });
      });

      onMounted(() => {
        let matched = route.matched; //获取菜单对应的路由信息
        state.list = matched;
      })
      // watch()中可以放置两个参数，第一个参数放入被监听数据，第二个参数放入回调函数用来进行数据操作
      watch(() => route.matched, (newVal, oldVal) => {
        let matched = newVal;
        state.list = matched; //更新路由菜单数组
        // console.log(oldVal);
        oldVal
      })
      return {
        ...toRefs(state), //toRefs() 将数据变成响应式数据
      }
    },

  }
</script>
<style>
</style>