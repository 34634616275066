<template>
  <div class="layout-navbars-breadcrumb-index">
    <div class="layout-navbars-breadcrumb">
      <el-radio-group v-model="isCollapse">
        <i class="layout-navbars-breadcrumb-icon iconfont" @click="navMenuState"
           :class="[getThemeConfig ? 'icon-zhankai-you' : 'icon-zhankai-zuo']"></i>
      </el-radio-group>
      <crumbs v-show="clientWidth >= 1000"/>
    </div>
    <div class="layout-navbars-breadcrumb-user">
      <el-dropdown :show-timeout="70" :hide-timeout="50" trigger="click" @command="switchFontSize">
        <span class="el-dropdown-link layout-navbars-breadcrumb-user-icon">
          <i class="fa fa-text-height icon-box"></i>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="" :disabled="disabledSize === ''">{{ $t(sizeList.user.dropdownDefault) }}
            </el-dropdown-item>
            <el-dropdown-item command="bigSize" :disabled="disabledSize === 'bigSize'">
              {{ $t(sizeList.user.dropdownbigSize) }}
            </el-dropdown-item>
            <el-dropdown-item command="small" :disabled="disabledSize === 'small'">
              {{ $t(sizeList.user.dropdownSmall) }}
            </el-dropdown-item>
            <el-dropdown-item command="mini" :disabled="disabledSize === 'mini'">
              {{ $t(sizeList.user.dropdownMini) }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <el-dropdown :show-timeout="70" :hide-timeout="50" trigger="click" @command="onLanguageChange">
        <span class="el-dropdown-link layout-navbars-breadcrumb-user-icon">
          <i class="fa fa-language icon-box"></i>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="zh">简体中文</el-dropdown-item>
            <el-dropdown-item command="en">English</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <div class="layout-navbars-breadcrumb-user-icon" @click="onSearchClick">
        <i class="icon-sousuo icon-box iconr"></i>
      </div>
      <fullScreen/>
      <el-dropdown :show-timeout="70" :hide-timeout="50" @command="onHandleCommandClick">
        <span class="layout-navbars-breadcrumb-user-link">
          <img src="/static/img/headPortrait.jpg" class="layout-navbars-breadcrumb-user-link-photo mr5"/>
          <span style="color:#404E67">{{ name }}</span>
          <i class="icon-arrow-down-s-line el-icon--right iconr" style="color:#404E67"></i>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="/Home">{{ $t('首页') }}</el-dropdown-item>
            <el-dropdown-item command="/setUp">{{ $t('个人设置') }}</el-dropdown-item>
            <el-dropdown-item divided command="logOut">{{ $t('退出登录') }}</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <search ref="searchRef"/>
  </div>
</template>
<script>
import {
  ref,
  reactive,
  toRefs,
  computed,
  onBeforeMount,
  getCurrentInstance,
  onMounted
} from 'vue'
import {
  fontVariant
} from '../homeData';
import {
  useStore
} from 'vuex'
import crumbs from './crumbs.vue'
import fullScreen from './fullScreen.vue'
import search from './search.vue'
import {
  infoAuth,
  logoutAuth
} from '@/http/api.js'
import {
  useRouter
} from 'vue-router';
import {
  ElMessageBox,
  ElMessage,
  ElNotification
} from 'element-plus';
import emitter from "@/utils/eventBus";
import {useI18n} from "vue-i18n";

function blackList(state) {
  infoAuth().then(res => {
    if (res.code == 200) {
      state.name = res.data.name
    } else {
      ElNotification({
        title: '失败',
        message: res.msg,
        type: 'error',
        duration: 2000,
      });
    }
  }).catch(err => {
    console.log(err, 'err');
  })
}

export default {
  name: 'Header',
  components: {
    crumbs,
    fullScreen,
    search
  },
  setup() {
    const {proxy}   = getCurrentInstance();
    const {t}       = useI18n()
    const router    = useRouter();
    const state     = reactive({
      fontVariant,
      value: '',
      clientWidth: '',
      sizeList: {
        user: ({
          dropdownDefault: '默认',
          dropdownbigSize: '大号',
          dropdownSmall: '小型',
          dropdownMini: '超小'
        })
      },
      // disabledSize: '',
      name: '',
    });
    const searchRef = ref();
    onMounted(() => {
      blackList(state)
    })
    // let command = ref(command)
    const store          = useStore()
    // 获取布局配置信息
    const getThemeConfig = computed(() => store.state.isCollapse);
    let navMenuState     = () => {
      store.commit('SET_COLLAPSE')
      // store.state.isCollapse = !store.state.isCollapse;
    };
    const onSearchClick  = () => {
      searchRef.value.openSearch();
    };
    //浏览器宽度
    const initMenuFixed  = (clientWidth) => {
      state.clientWidth = clientWidth;
    };
    onBeforeMount(() => {
      initMenuFixed(document.body.clientWidth);
      proxy.mittBus.on('layoutMobileResize', (res) => {
        initMenuFixed(res.clientWidth);
      });
    });
    // 下拉菜单点击时
    const onHandleCommandClick = (path) => {
      if (path === 'logOut') {
        ElMessageBox({
          closeOnClickModal: false,
          closeOnPressEscape: false,
          title: t('提示'),
          message: t('此操作将退出登录是否继续'),
          showCancelButton: true,
          confirmButtonText: t('确定'),
          cancelButtonText: t('取消'),
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              instance.confirmButtonText    = t('退出中');
              setTimeout(() => {
                done();
                setTimeout(() => {
                  instance.confirmButtonLoading = false;
                }, 300);
              }, 700);
            } else {
              done();
            }
          },
        }).then(() => {
          logoutAuth().then(res => {
            if (res.code == 200) {
              router.push('/');
              setTimeout(() => {
                ElMessage.success(t('退出成功'));
              }, 300);
            } else {
              ElNotification({
                title: t('失败'),
                message: res.msg,
                type: 'error',
                duration: 2000,
              });
            }
          }).catch(err => {
            console.log(err, 'err');
            router.push('/');
            setTimeout(() => {
              ElMessage.success(t('退出成功'));
            }, 300);
          })
        }).catch(() => {
        });
      } else {
        router.push(path);
      }
    };
    return {
      navMenuState,
      searchRef,
      onSearchClick,
      ...toRefs(state),
      getThemeConfig,
      initMenuFixed,
      onHandleCommandClick,
    }
  },
  methods: {
    switchFontSize(command) {
      // command 此处是形参，可以是 value，随意
      const root = document.querySelector(':root')
      let size   = ''
      switch (command) {
        case '':
          this.disabledSize = '';
          size              = '16px'
          break;
        case 'bigSize':
          this.disabledSize = 'bigSize';
          size              = '20px'
          break;
        case 'small':
          this.disabledSize = 'small';
          size              = '13px'
          break;
        case 'mini':
          this.disabledSize = 'mini';
          size              = '10px'
          break;
      }
      root.style.fontSize = size
    },
    onLanguageChange(command) {
      localStorage.setItem("locale", command);
      emitter.emit("switchLang", {lang: command});
      this.$i18n.locale = command;
      this.$message({
        type: "success",
        message: this.$t("切换成功")
      });
    },
  },
  data() {
    return {
      disabledSize: ''
    }
  }
}
</script>
<style lang="scss" scoped>
// 这里 root 无效，只能放全局

@import "../../../theme/home/homeHeader.scss";

.icon-box {
  font-size: 16px;
}
</style>
