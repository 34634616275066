<template>
  <div class="layout-navbars-breadcrumb-user-icon mr10" @click="onLayoutSetingClick">
    <i class="icon-box iconr" :class="fullscreen == false ? 'icon-zhankai1' : 'icon-zhankai'"></i>
  </div>
</template>
<script>
  export default ({
    name: 'fullScreen',
    setup() {},
    data() {
      return {
        fullscreen: false
      }
    },
    methods: {
      onLayoutSetingClick() {
        let element = document.documentElement;
        if (this.fullscreen) {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
          }
        } else {
          if (element.requestFullscreen) {
            element.requestFullscreen();
          } else if (element.webkitRequestFullScreen) {
            element.webkitRequestFullScreen();
          } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
          } else if (element.msRequestFullscreen) {
            // IE11
            element.msRequestFullscreen();
          }
        }
        this.fullscreen = !this.fullscreen;
      }
    },
  })
</script>