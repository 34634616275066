import 'babel-polyfill'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
import './theme/index.scss'
import './font-awesome/css/font-awesome.min.css'
import i18n from './lang'
import XLSX from 'xlsx'
import moment from 'moment'
import mitt from 'mitt';
import axios from 'axios';
import './assets/iconfont/iconfont.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App)
installElementPlus(app)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(store).use(router).use(i18n).use(XLSX).use(moment).mount('#app');
app.config.globalProperties.mittBus = mitt();
app.config.globalProperties.$axios  = axios;
