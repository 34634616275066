<template>
	<div class="layout-search-dialog">
		<el-dialog v-model="isShowSearch" width="300px" destroy-on-close :modal="false" fullscreen :show-close="false">
			<el-autocomplete v-model="menuQuery" :fetch-suggestions="menuSearch" :placeholder="$t('菜单搜索')"
				prefix-icon="el-icon-search" ref="layoutMenuAutocompleteRef" @select="onHandleSelect" @blur="onSearchBlur">
				<template #default="{ item }">
					<div><i v-if="item.meta.icon" :class="item.meta.icon" class="mr10"></i><span>{{$t(item.meta.title+'')}}</span>
					</div>
				</template>
			</el-autocomplete>
		</el-dialog>
	</div>
</template>
<script>
	import {
		reactive,
		defineComponent,
		toRefs,
		ref,
		nextTick
	} from 'vue'
	import {
		useRouter
	} from 'vue-router';
	import {
		useI18n
	} from 'vue-i18n';
	import {
		routes
	} from '../../../router/index';
	export default defineComponent({
		name: 'search',
		setup() {
			const layoutMenuAutocompleteRef = ref();
			const router = useRouter();
			const {
				t
			} = useI18n();
			// const store = useStore();
			const state = reactive({
				isShowSearch: false,
				menuQuery: '',
				tagsViewList: [],
			});
			// 搜索弹窗打开
			const openSearch = () => {
				state.menuQuery = '';
				state.isShowSearch = true;
				nextTick(() => {
					layoutMenuAutocompleteRef.value.focus();
				});
				state.tagsViewList = state.tagsViewList.filter((item) => {
					return item.meta.isShow == true
				})
			};
			// 搜索弹窗关闭
			const closeSearch = () => {
				state.isShowSearch = false;
			};

			// 菜单搜索数据过滤
			const menuSearch = (queryString, cb) => {
				let results = queryString ? state.tagsViewList.filter(createFilter(queryString)) : state.tagsViewList;
				cb(results);
			};
			// 菜单搜索过滤
			const createFilter = (queryString) => {
				return (restaurant) => {
					// tagsViewList = state.tagsViewList
					return (
						restaurant.path.toLowerCase().indexOf(queryString.toLowerCase()) > -1 ||
						restaurant.meta.title.toLowerCase().indexOf(queryString.toLowerCase()) > -1 ||
						t(restaurant.meta.title).indexOf(queryString.toLowerCase()) > -1
					)
				}
			}
			// 递归
			function loopChildren(arr, callback) {
				if (!arr) return
				arr.forEach(item => {
					if (item.children && item.children.length > 0) {
						loopChildren(item.children, callback)
					}
					callback(item)

				})
			}
			//初始化菜单数据
			loopChildren(routes, item => {
				const {
					path,
					meta: {
						title,
						icon,
						isShow,
						isLink,
						isIframe,
					}
				} = item
				state.tagsViewList.push({
					path,
					meta: {
						title,
						icon,
						isShow,
						isLink,
						isIframe,
					}
				})
			})

			// 当前菜单选中时
			const onHandleSelect = (item) => {
				let {
					path,
					redirect
				} = item;
				if (item.isLink && !item.isIframe) window.open(item.isLink);
				else if (redirect) router.push(redirect);
				else router.push(path);
				closeSearch();
			};
			// input 失去焦点时
			const onSearchBlur = () => {
				closeSearch();
			};
			return {
				layoutMenuAutocompleteRef,
				openSearch,
				closeSearch,
				menuSearch,
				onHandleSelect,
				onSearchBlur,
				...toRefs(state),
			}
		}
	});
</script>
<style lang="scss" scoped>
	.layout-search-dialog {
		:deep(.el-dialog) {
			box-shadow: unset !important;
			border-radius: 0 !important;
			background: rgba(0, 0, 0, 0.5);
		}

		:deep(.el-autocomplete) {
			width: 560px;
			position: absolute;
			top: 100px;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	.mr10 {
		font-size: 15px !important;
		display: inline-block;
		vertical-align: middle;
		width: 24px;
		text-align: center;
	}

	@media only screen and (max-width: 600px) {
		.layout-search-dialog {

			:deep(.el-autocomplete) {
				width: 80%;
				position: absolute;
				top: 100px;
				left: 50%;
				transform: translateX(-50%);
			}
		}

	}
</style>
