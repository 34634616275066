const en = {
    //PUBLIC
    清空: 'Clear',
    成功: 'Success',
    失败: 'Fail',
    提示: 'Tip',
    警告: 'Warning',
    错误: 'Error',
    状态: 'Status',
    姓名: 'Name',
    地址: 'Address',
    操作: 'Operation',
    确定: 'Confirm',
    无数据: 'Null',
    取消: 'Cancel',
    保存: 'Save',
    新增: 'Add',
    添加成功: 'Added successfully',
    删除成功: 'Deleted successfully',
    编辑成功: 'Edited successfully',
    解绑成功: 'Unbind successfully',
    当天: 'The day',
    昨天: 'Yesterday',
    本周: 'This week',
    上周: 'Last week',
    本月: 'This month',
    上月: 'Last month',
    退出中: 'Exiting',
    此操作将退出登录是否继续: 'This operation will log you out, do you want to continue?',
    退出成功: 'Exit successfully!',
    端口: 'Port',
    更新: 'Update',
    安全验证: 'Security verification',
    加载中: 'Loading...',

    数据库: 'Database',
    数据库地址: 'Database Host',
    数据库用户名: 'Database Username',
    数据库密码: 'Database Password',
    管理员: 'Administrators',
    管理员用户名: 'Administrators Username',
    管理员密码: 'Administrator Password',
    管理员确认密码: 'Administrator Confirms Password',
    请输入数据库地址: 'Enter Database Host',
    请输入Redis地址: 'Enter Redis Host',
    请输入数据库用户名: 'Enter Database Username',
    请输入数据库密码: 'Enter Database Password',
    请输入Redis密码: 'Enter Redis Password',
    配置文件已写入即将开始安装: 'The configuration file has been written and installation is about to begin...',
    配置文件已写入即将开始更新: 'The configuration file has been written and will soon be updated...',
    安装成功即将跳转至登录页: 'Installation successful! Jumping to login page soon!',
    更新成功即将跳转至登录页: 'updated successful! Jumping to login page soon!',
    安装: 'Install',
    请选择安装类型: 'Select the installation type',
    安装类型: 'Installation type',
    初始化: 'initialization',
    连接地址: 'Connection address',
    请输入WebSocket地址: 'Please enter WebSocket address',
    WebSocket连接失败: 'WebSocket connection failed',
    请求错误: 'Request Error',

    登录成功: 'Login Successful',
    请输入手机号或邮箱: 'Enter your phone number or email',
    获取验证码: 'Get code',
    重置: 'Reset',
    保存截图: 'save screenshot',
    验证失败: 'Validation failed',
    向右滑动完成验证: 'Swipe right to complete verification',

    首页: 'Home',
    下拉刷新: 'Pull down refresh',
    上拉加载: 'Pull up loading',
    切换成功: 'Switching succeeded',
    语言: 'Language',
    默认: 'Default',
    大号: 'large',
    小型: 'Small',
    超小: 'Tiny',
    分机: 'Extensions',
    分机设置: 'Extensions Settings',
    呼叫控制: 'Call Control',
    呼叫功能: 'Call Functionality',
    群组: 'Groups',
    分组: 'Extension Groups',
    分组设置: 'Group Settings',
    队列: 'Queue',
    队列设置: 'Queue Settings',
    电话会议: 'Conference Call',
    部门: 'Department',
    部门设置: 'Department Settings',
    中继: 'SIP Relays',
    中继设置: 'SIP Relays Settings',
    总机: 'Switchboard',
    IVR总机: 'IVR Switchboard',
    数据中心: 'Data center',
    通话记录: 'Call Log',
    通话统计: 'Call Statistics',
    安全: 'Security',
    IP黑名单: 'IP Blacklist',
    黑名单号码: 'Blacklisted Numbers',
    安全设置: 'Security Settings',
    系统参数: 'System Parameter',
    设置: 'Settings',
    功能设置: 'Features',
    菜单搜索: 'Search',
    个人设置: 'Account Settings',
    退出登录: 'Logout',
    请输入用户名: 'Enter username',
    请输入密码: 'Enter password',
    请输入新密码: 'Enter new password',
    请确认密码: 'Confirm new password',
    请输入验证码: 'Enter verification code',
    记住我: 'Remember me',
    忘记密码: 'Forgot password',
    登录: 'SIGN IN',
    呼入规则: 'Inbound Rules',
    呼出规则: 'Outbound Rules',
    删除前置位数: 'Remove leading digits',
    前置号码: 'Front number',
    匹配模式不能为空: 'Matching mode cannot be empty',
    删除前置位数只能设置数字: 'Removing leading digits can only set digits',
    前置号码格式错误: 'Front number format error',

    今日通话: 'Call Today',
    呼入接听: 'Call in success',
    呼入接听比率: 'Call in success ratio',
    呼出接听: 'Call out success',
    呼出接听比率: 'Call out success ratio',
    事件日志: 'Event log',
    系统监控: 'System Monitor',
    CPU使用率: 'CPU usage',
    内存使用率: 'Memory usage',
    磁盘使用率: 'Disk usage',
    内存: 'Memory',
    磁盘: 'Disk',

    新增分机: 'Add extension',
    编辑分机: 'Edit extension',
    导入分机: 'Import extension',
    下载模版: 'Download template',
    下载文件模板: 'Download file template',
    批量添加分机: 'Bulk add extension',
    批量添加: 'Bulk Add',
    分机号码: 'Extension number',
    分机号或名称: 'Number or Name',
    分机号: 'Extension',
    分机密码: 'Password',
    名称: 'Name',
    职务: 'Job',
    禁止外呼: 'Disable Outgoing',
    禁止: 'Disable',
    局内: 'Intercom',
    本地: 'Local',
    全国: 'Domestic',
    国内: 'Domestic',
    国际: 'International',
    录音: 'Recordings',
    类型: 'Type',
    国际权限: 'International',
    普通分机: 'General',
    队列分机: 'Queue',
    创建数量: 'Count',
    请选择要删除的: 'Please select to delete',
    添加: 'Add',
    编辑: 'Edit',
    删除: 'Delete',
    导入: 'Import',
    导出: 'Export',
    打包录音: 'Pack recording',
    修改: 'Ok',
    查询: 'Search',
    请选择权限: 'Select permission',
    请选择中继: 'Select relay',
    请输入分机号: 'Enter an extension',
    分机号只允许添加数字: 'Only allow numbers to be added',
    请输入分机密码: 'Enter password',
    分机密码限制6到8位且必须包含大小写字母和数字: 'Wrong format',
    请选择类型: 'Select type',
    请输入分机数量: 'Enter quantity',
    你确定要删除该分机吗: 'Are you sure you want to delete this extension?',
    请选择要删除的分机: 'Please select the extension to delete',
    等待时间s: 'waiting time(s)',
    密码为小于六位的数字: 'Password is less than six digits',
    请选择留言提示音: 'Select a message tone',
    请选择时间条件: 'Select a time condition',
    特殊字符定义如下: 'Special characters are defined as follows',
    X代表从0到9的任意数字: 'X represents any number from 0 to 9',
    Z代表从1到9的任意数字: 'Z represents any number from 1 to 9',
    N代表从2到9的任意数字: 'N represents any number from 2 to 9',
    代表括内任数字例如在此例中代表号: 'Represents any number in brackets, such as the number in this example',
    码: 'Code',
    通配符代表匹配到多个数字例如9011: 'The wildcard "." means match 1 or more numbers. Example: "9011."',
    代表匹配任何以9011开头的号码但不包含9011: 'means match any number starting with 9011 but not including 9011',
    通配符代表匹配0到多个数字例如9011: 'The wildcard "!" matches zero or more numbers. Example: "9011!"',
    代表匹配任何以9011开头的号码包含9011: 'means match any number starting with 9011, including 9011',

    新增部门: 'Add department',
    编辑部门: 'Edit department',
    部门编辑: 'Edit',
    部门删除: 'Delete',
    部门新增: 'Add',
    归属: 'Ascription',
    网关: 'Gateway',
    搜索分机: 'Search Extension',
    请选择部门: 'Select department',
    请选择网关: 'Select gateway',
    移除: 'Remove',
    分机成员: 'Extension member',
    可用分机: 'Available extension',
    查找部门: 'Find a department',
    你确定要删除该部门吗: 'Are you sure you want to delete this department?',
    你确定要移除该分机吗: 'Are you sure you want to remove this extension?',
    默认为一级部门: 'Default is first-level department',
    请选择呼叫权限: 'Select call permission',
    请输入名称: 'Enter a name',

    新增分组: 'Add group',
    编辑分组: 'Add group',
    分组新增: 'Add',
    分组编辑: 'Add',
    分组名称或号码: 'Group name or number',
    号码: 'Number',
    成员数量: 'Number of members',
    代答组: 'Answer group',
    同振: 'Ring group',
    顺振: 'Hunt group',
    请输入分组名称: 'Enter a group name',
    请输入号码: 'Enter a number',
    组号码只允许添加数字: 'Group numbers only allow adding digits',
    请选择分组类型: 'Select a grouping type',
    你确定要删除该分组吗: 'Are you sure you want to delete this group?',
    一天: 'One days',
    两天: 'Two days',
    三天: 'Three days',
    四天: 'Fore days',
    五天: 'Five days',
    六天: 'Six days',
    七天: 'Seven days',
    三十天: 'Thirty days',
    转接: 'Transfer',

    新增队列: 'Add queue',
    编辑队列: 'Edit queue',
    队列新增: 'Add',
    队列编辑: 'Edit',
    基本信息: 'Basic information',
    队列成员: 'Queue members',
    排队人数: 'Queue size',
    提示音间隔: 'Interval',
    失败提示音: 'Queue failure prompts',
    等待提示音: 'Waiting for prompts',
    提示音播放顺序: 'Tip sound playback sort',
    前: 'Before',
    后: 'After',
    等待超时时间: 'Timeout',
    队列号码只允许添加数字: 'Queue numbers only allow adding digits',
    请输入最大排队人数: 'Enter the maximum number of people in line',
    请输入等待间隔: 'Enter a waiting interval',
    请选择失败音: 'Select a failure tone',
    请选择等待音: 'Select a waiting tone',
    请选择播放顺序: 'Select the playback order',
    等待超时时间s: 'Wait timeout(s)',
    提示音间隔s: 'Beep interval(s)',
    你确定要删除该队列吗: 'Are you sure you want to delete the queue?',
    队列名称或号码: 'Queue name or number',
    提示音状态: 'Prompt sound status',

    中继新增: 'Add',
    新增中继: 'Add relays',
    编辑中继: 'Edit relays',
    导入中继: 'Import relays',
    中继名称: 'Relay name',
    基本: 'Basic',
    编码: 'Codec',
    支持编码: 'Support Coding',
    高级: 'Advanced',
    改编来电号码: 'Adapt Call ID',
    中继类型: 'Relay Type',
    注册中继: 'Register Relay',
    点对点中继: 'Peer Relay',
    账号中继: 'Account Relay',
    中继号码: 'Relay Number',
    请输入中继号码: 'Enter relay number',
    号码名称: 'Relay Number Name',
    系统状态: 'System Status',
    协议: 'Protocol',
    传输协议: 'Transport',
    域名IP地址: 'Hostname/IP',
    主域服务器: 'Domain',
    用户名: 'Username',
    密码: 'Password',
    认证名称: 'Authentication Name',
    最大并发数: 'Maximum Channels',
    VoIP设置: 'VoIP Settings',
    心跳检测: 'Keep alive',
    DTMF模式: 'DTMF Mode',
    DID设置: 'DID Settings',
    DID号码: 'DID Number',
    DNIS名称: 'DNIS Name',
    配置相应的模式来重新格式化呼入和呼出的号码: 'Configure the corresponding mode to reformat the incoming and outgoing numbers',
    主叫号码变换: 'Calling number conversion',
    被叫号码变换: 'Called number conversion',
    原模式: 'Source Pattern',
    模式: 'Model',
    请选择中继类型: 'Select a relay type',
    请选择传输协议: 'Select a transport protocol',
    请输入端口号: 'Enter the port number',
    请输入认证名称: 'Enter a certification name',
    请输入域名ip: 'Enter the domain name ip',
    请填写被叫模式: 'Fill in the called mode',
    请填写主叫模式: 'Fill in the calling pattern',
    请填写号码变换模式: 'Fill in the number change mode',
    代理服务器: 'Proxy server',
    请选择最大并发数: 'Select maximum concurrency',
    你确定要删除该中继吗: 'Are you sure you want to delete this relay?',
    请选择要删除的中继: 'Select a relay to delete',
    域名Ip: 'Domain/Ip',
    新增DOD绑定分机: 'Add DOD bound extension',
    编辑DOD绑定分机: 'Edit DOD bound extension',
    导入DOD绑定分机: 'Import DOD bound extension',
    请输入DOD号码: 'Please enter the DOD number',
    请选择绑定分机: 'Please select the bound extension',
    来电号码变换: 'Change of incoming phone number',
    对端提供账号密码等认证信息需要向对端设备进行注册的中继类型通常用于通过互联网对接时己端没有静态公网IP需要向对端设备进行注册或对端设备强制要求注册的场景下使用: 'Provide authentication information such as account and password to the other end, and register the relay type with the other end device. It is usually used to register with the peer device when there is no static public IP address on the existing end when connecting through the Internet. Or used in scenarios where registration is mandatory for end devices.',
    两端通过IP地址对接无需注册认证通常用于企业内部局域网环境对接本地网关对接或互联网固定IP地址对接的场景: 'Both ends are connected through IP addresses, without the need for registration and authentication. It is usually used for intranet environment connection, local gateway connection or Internet fixed IP address connection.',
    账号中继一般用于对接PBX与其他设备你可以在IPPBX创建一个账号其他设备通过注册该账号连接到IPPBX: 'Account relay is generally used to connect PBX with other devices. You can create an account on IPPBX, and other devices can connect to IPPBX by registering this account.',

    替换模式: 'Replace Pattern',
    替换号码: 'Replace Number',

    新增呼入路由: 'Add Inbound Route',
    编辑呼入路由: 'Edit Inbound Route',
    路由名称: 'Route name',
    DID匹配模式: 'DID Pattern',
    来电匹配模式: 'Caller ID Pattern',
    启用时间条件: 'Enable Time Condition',
    时间条件: 'Time Condition',
    目的地: 'Destination',
    挂机: 'Hang up',
    你确定要删除该路由吗: 'Are you sure you want to delete this route?',
    请选择要删除的路由: 'Select a route to delete',
    请选择目的类型: 'Select purpose type',
    请选择目的号码: 'Select a destination number',
    搜索中继: 'Search relay',
    请选择中继或中继组: 'Select relay or relay group',
    号码变换编辑成功: 'Number change edited successfully',
    全局DOD: 'Global DOD',
    导入DOD: 'Import DOD',
    配置相应的模式来重新格式化呼入的号码: 'Configure the corresponding modes to reformat incoming numbers.',
    配置相应的模式来重新格式化呼出的号码: 'Configure the corresponding mode to reformat the outgoing number.',
    主叫号码匹配模式: 'Calling number matching mode',
    请选择时间条件可多选: 'Please select a time condition (multiple)',
    DID号码范围匹配到分机范围: 'DID number range matches to extension',
    DID号码规则匹配到分机: 'DID number rule matching to extension',
    DID号码匹配到指定分机: 'DID number matches to specified extension',
    DID号码规则: 'DID number rules',
    匹配分机: 'Extension',
    DID范围: 'DID range',
    分机范围: 'Extension range',
    请选择DID匹配模式: 'Please select DID matching mode',
    请输入DID范围: 'Please enter the DID rang',
    请选择匹配分机: 'Please select a matching extension',
    新增DID匹配分机: 'Add DID matching extension',
    编辑DID匹配分机: 'Edit DID matching extension',
    导入DID匹配分机: 'Import DID matching extension',
    请输入DID号码: 'Please enter the DID number',
    请选择要删除的数据: 'Please select the data to be deleted',
    上传成功: 'Upload successful',
    你确定要删除该数据吗: 'Are you sure you want to delete this data?',


    中继组设置: 'Relay Group Settings',
    中继组名称: 'Relay Group Name',
    备注: 'Remark',
    新增中继组: 'Add Relay Group',
    编辑中继组: 'Edit Relay Group',
    中继组成员: 'Relay Group Members',
    中继成员: 'Relay member',
    成员中继: 'Relays',
    呼叫分配类型: 'Call allocation type',
    优先级: 'Priority',
    权重: 'Weight',
    请输入中继组名称: 'Enter relay group name',
    请选择呼叫分配类型: 'Select call allocation type',
    你确定要删除该中继组吗: 'Are you sure you want to delete this relay group?',
    DOD号码: 'DOD Number',
    绑定分机: 'Bind Extension',
    请选择: 'Select',

    新增呼出路由: 'Add outbound route',
    编辑呼出路由: 'Edit outbound route',
    导入呼出路由: 'Import outbound route',
    拨号模式: 'Dial Patterns',
    呼出模式: 'Patterns',
    删除前缀位数: 'Strip',
    前置: 'Prepend',
    循环抓取: 'Rrmemory Hunt',
    请输入外呼权重: 'Enter outbound call weight',
    请选择外呼权限: 'Select outgoing call permission',
    请选择目标类型: 'Select a target type',
    请选择状态: 'Select status',
    外呼优先级: 'Outbound call priority',
    请输入优先级: 'Enter priority',


    总机新增: 'Add',
    新增总机: 'Add switchboard ',
    总机编辑: 'Edit',
    编辑总机: 'Edit switchboard',
    请输入总机名: 'Please enter the switchboard name',
    总机名称: 'Switchboard name',
    参数设置: 'Parameter setting',
    语音选项: 'Voice options',
    按键设置: 'Key setting',
    普通总机: 'General switchboard',
    直拨总机: 'Direct dial switchboard',
    直拨分机: 'Direct dial extension',
    按键等待: 'Timeout(sec)',
    按键间隔: 'Inter-digit-timeout(sec)',
    按键失败: 'Max-failures(times)',
    超时次数: 'Digit-len(times)',
    你确定要删除该总机吗: 'Are you sure you want to delete this switchboard?',
    请输入按键等待时间: 'Enter the key wait time',
    按键等待时间格式错误: 'Key wait time format error',
    请输入按键间隔时间: 'Enter the time between keystrokes',
    按键间隔时间格式错误: 'Key interval time format error',
    请输入按键失败次数: 'Enter the number of failed keystrokes',
    按键失败次数格式错误: 'Key failure count format error',
    请输入超时次数: 'Enter the number of timeouts',
    超时次数格式错误: 'Timeout count format error',
    请输入内容: 'Enter content',
    转其他号码: 'Transfer other numbers',
    转Ivr: 'Transfer ivr',
    按键格式错误: 'Wrong key format',
    请填写按键设置: 'Fill in the button settings',
    请完整填写按键设置: 'Fill in the button settings completely',
    欢迎语: 'Welcome-sound',
    菜单提示音: 'Menu-sound',
    按键: 'Key',

    重听: 'Hard listening',
    菜单: 'Menu',
    转分机: 'Transfer',
    长提示音: 'Greet-long',
    短提示音: 'Greet-short',
    无效提示音: 'Invalid-sound',
    退出提示音: 'Exit-sound',
    主叫回拨: 'Call Tracking( Direct forward)',
    主叫追踪: 'Call Tracking(Prompt forward)',
    请选择主叫追踪时间: 'Please select caller tracking time',
    目的: 'Destination',
    目的号码: 'Destination number',


    通话记录和录音: 'CDR and Recording',
    筛选: 'Filter',
    时间: 'Time',
    开始时间: 'Start Time',
    结束时间: 'End Time',
    通话状态: 'Call Status',
    呼入: 'Inbound',
    呼出: 'Outbound',
    拨通: 'Answer',
    未拨通: 'No Answer',
    主叫: 'Calling',
    被叫: 'Called',
    主叫号码: 'Calling Number',
    被叫号码: 'Called Number',
    最大通话时长: 'Maximum Call Duration',
    最小通话时长: 'Minimum Call Duration',
    下载通话记录: 'Download CDR',
    下载录音: 'Download Recordings',
    呼叫开始时间: 'Call Time',
    通话时长: 'Talk Duration',
    呼叫类型: 'Call Type',
    呼叫方向: 'Call Direction ',
    费用: 'Expenses',
    录音文件选项: 'Recording Options',
    通话方向: 'Call Direction',

    双击部门所在的行进入部门分机统计: 'Double-click on the line where the Department is located to enter the branch statistics',
    纯数据保存: 'Pure Data Download',
    本地保存: 'Download Locally',
    列表: 'List',
    图表: 'Chart',
    总次数: 'Total',
    通话次数: 'Number Of Calls ',
    接听比率: 'Answering Rate',
    平均通话时长: 'Average Call Duration',
    平均时长: 'Average Duration',
    次数: 'Frequency',
    次: '',
    时长秒: 'Duration(sec)',
    呼入时长: 'Incoming Duration',
    呼出时长: 'Outgoing Duration',
    呼入次数: 'Incoming Calls',
    呼出次数: 'Outgoing Calls',
    返回上一级部门: 'Return to previous department',

    信息: 'Information',
    IP地址或号码: 'IP address or number',
    请填写IP地址或号码信息: 'Please fill in the IP address or number',
    请选择要删除的黑名单: 'Please select the blacklist to be deleted',
    新增黑名单: 'Add Blacklist',
    编辑黑名单: 'Edit Blacklist',
    IP地址: 'IP Address',
    描述: 'Description',
    你确定要删除该ip吗: 'Are you sure you want to delete this ip?',
    请选择要删除的ip: 'Select the ip to delete',
    ip格式错误: 'Wrong ip format',
    请输入ip地址: 'Enter ip address',
    请输入描述: 'Enter a description',

    安全组: 'Security Group',
    新增安全组: 'Add Security Group',
    编辑安全组: 'Edit Security Group',
    请输入安全组名称: 'Please enter the security group name',
    请输入备注信息: 'Please enter note information',
    安全组名称: 'Security Group Name',
    端口范围: 'Port Range',
    规则: 'Rules',
    安全组成员: 'Security Group Members',
    应用于分机: 'Applied to extension',
    应用分机: 'Application extension',
    新增安全组规则: 'Add security group rules',
    编辑安全组规则: 'Edit security group rules',
    允许: 'Allow',
    请输入IP地址: 'Please enter the IP address',
    请定义优先级: 'Please define priority',

    你可以更改电话系统登陆用户名和密码需要在管理控制台中的安全设置选项里更改: 'You can change the login user name and password of the phone system, which need to be changed in the security settings option in the management console',
    旧密码: 'Old Password',
    新密码: 'New password',
    确认密码: 'Confirm Password',
    请输入旧密码: 'Enter old password',
    八到12位包含大小写字母和数字: '8 to 12 digits, Including uppercase and lowercase letters and number',


    会议新增: 'Add',
    会议编辑: 'Edit',
    会议号或名称: 'Number or Name',
    会议: 'Conference',
    容量: 'Capacity',
    开放型: 'Open type',
    密码型: 'Cryptographic type',
    创建时间: 'Creation time',
    你确定要删除该会议吗: 'Are you sure you want to delete this meeting?',
    请输入最大成员数: 'Enter a maximum number of members',
    请选择语言: 'Select language',
    会议号只允许添加数字: 'Only numbers can be added',
    长度三到六位的数字: 'A number with a length of 3 to 6 digits',

    假期: 'Holiday',
    月份: 'Month',
    星期: 'Week',
    所有日期: 'All dates',
    开始日期: 'Start date',
    结束日期: 'End date',
    新增时间条件: 'Add time condition',
    编辑时间条件: 'Edit time condition',
    全选: 'Select all',
    反选: 'Select invert',
    星期日: 'Sunday',
    星期一: 'Monday',
    星期二: 'Tuesday',
    星期三: 'Wednesday',
    星期四: 'Thursday',
    星期五: 'Friday',
    星期六: 'Saturday',
    一月: 'January',
    二月: 'February',
    三月: 'March',
    四月: 'April',
    五月: 'May',
    六月: 'June',
    七月: 'July',
    八月: 'August',
    九月: 'September',
    十月: 'October',
    十一月: 'November',
    十二月: 'December',
    请选择要删除的假期: 'Select a holiday to delete',
    你确定要删除该假期吗: 'Are you sure you want to delete this holiday?',
    你确定要删除该时间吗: 'Are you sure you want to delete this time?',
    请选择要删除的时间: 'Select a time to delete?',
    请选择假期类型: 'Select holiday type',
    请填写开始时间: 'Fill in the start time',
    请填写结束时间: 'Fill in the end time',
    请选择星期: 'Select a week',

    功能码: 'Feature Code',
    功能码输入超时时间: 'Feature Code Digits Timeout',
    一键录音: 'One Touch Recording',
    全局录音切换: 'Auto Recording Switch',
    分机的语音信箱: "Extension's Voicemail",
    收听语音信箱: 'Check Voicemail',
    分机语音信箱: 'Voicemail for Extension',
    语音信箱菜单: 'Voicemail Main Menu',
    通话转接: 'Transfer',
    盲转接: 'Blind Transfer',
    指定转接: 'Attended Transfer',
    指定转接时长: 'Attended Transfer Timeout(s)',
    转移按键超时时间: 'Transfer Digit Timeout(s)',
    截答: 'Call Pickup',
    同组截答: 'Call Pickup',
    指定截答: 'Extension Pickup',
    呼叫转移: 'Call Forwarding',
    无条件转移: 'Forward All Calls',
    遇忙转移: 'Forward When Busy',
    无应答转移: 'Forward No Answer',
    免打扰: 'Do Not Disturb',
    启用免打扰: 'Enable Do Not Disturb',
    取消免打扰: 'Disable Do Not Disturb',
    提示音: 'Voice Prompts',
    提示音选项: 'Prompt Preference',
    等待音乐: 'Music on Hold',
    播放呼叫转移提示音: 'Play Call Forwarding Prompt',
    呼叫转移等待音乐: 'Music on Hold for Call Forwarding',
    被叫号码无效提示音: 'Invalid Phone Number Prompt',
    中继忙提示音: 'Busy Line Prompt',
    呼叫失败提示音: 'Dial Failure Prompt',
    事件中心提示音: 'Event Center Prompt',
    一键录音开始提示音: 'One Touch Recording Start Prompt',
    一键录音结束提示音: 'One Touch Recording End Prompt',
    新增等待音乐列表: 'Create New Playlist',
    播放顺序: 'Playlist Order',
    随机: 'Random',
    按英文字母: 'Alphabetical',
    选择等待音乐列表: 'Choose Music on Hold Playlist',
    上传新的等待音乐: 'Upload New Music',
    浏览: 'Browse',
    上传: 'Upload',
    自定义提示音: 'Custom Prompts',
    录制新提示音: 'Record New',
    请设置功能码: 'Set the function code',

    权限: 'Permission',
    用户管理: 'User Management',
    新增用户: 'Add User',
    权限管理: 'Permission Management',
    新增权限: 'Add Permission',
    权限名称: 'Permission Name',
    系统设置: 'System Settings',
    授权管理: 'Authorization Management',
    配置管理: 'Config Management',
    导入授权文件: 'Import Authorization File',
    上传失败: 'Import Failed!',
    总并发: 'Total Concurrency',
    总caps: 'Total Caps',

    启用: 'Enabled',
    禁用: 'Disabled',
    注册: 'Registered',
    未注册: 'Unregistered',
    基本设置: 'Basic Set Up',
    高级设置: 'Advanced Set Up',
    注册状态: 'Register Status',
    出局外显: 'Outbound Number',
    无条件转接: 'All Calls Transfer',
    无应答转接: 'No Answer Transfer',
    遇忙转接: 'Busy Transfer',
    等待时间: 'Waiting Time',
    转接号码: 'Transfer Number',
    呼转外显: 'Outbound Number',
    语音信箱: 'Voicemail',
    留言提示音: 'Voice Prompts',
    部门成员: 'Department Members',
    呼叫权限: 'Call Permission',
    成员分机: 'Extensions',
    分组成员: 'Group Members',
    试听: 'Audition',
    域名ip: 'Hostname/IP',
    并发数: 'Maximum Channels',
    可用编码: 'Available Codec',
    已选编码: 'Selected Codec',
    呼入路由: 'Inbound Routes',
    匹配模式: 'Match Pattern',
    目的类型: 'Type',
    正常路由: 'Ordinary Routes',
    DID路由: 'DID Routes',
    可用中继: 'Available Relays',
    已选中继: 'Selected Relays',
    呼出路由: 'Outbound Routes',
    目标类型: 'Type',
    中继组: 'Relay Group',
    外呼权限: 'Call Permission',
    外呼权重: 'Call Order',
    号码变换: 'Number Change',
    呼出路由成员: 'Outbound Route Members',
    更新时间: 'Update Time',
    会议室: 'Conference Room',
    新增假期: 'Add Holiday',
    编辑假期: 'Edit Holiday',
    假期类型: 'Type',
    日期: 'Date',
    说明: 'Explain',
    休息日: 'Rest Day',
    工作日: 'Weekdays',
    节日: 'Festival',
    节假日: 'Festival',
    请选择日期: 'Select A Date',

    添加提示音: 'Add Media',
    提示音名称: 'Prompt Tone Name',
    提示音上传: 'Upload',
    上传文件: 'Upload File',
    媒体名称: 'Name',
    你确定要删除该媒体吗: 'Are you sure you want to delete this media?',
    请选择要删除的媒体: 'Select media to delete',
    限制上传wav文件8k采样率: 'Limit uploading wav files, 8k sample rate',
    文件大小不能超过20M: 'File size cannot exceed 20M',
    录制: 'Transcribe',

    文本: 'Text',
    转语音: 'To Voice',

    日志管理: 'Log Management',
    操作日志: 'Operation Log',
    操作对象: 'Operation Object',
    操作类型: 'Operation Type',
    操作时间: 'Operation Time',
    对象: 'Object',
    内容: 'Content',
    文件名称: 'File Name',
    文件大小: 'File Size',
    服务类型: 'Server Type',
    下载: 'Download',
    系统: 'System',
    服务日志: 'Server Log',
    全部: 'All',
    媒体: 'Media',
    其它: 'Other',
    黑名单: 'Blacklist',

    性能设置: 'Performance Settings',
    网络设置: 'Network Settings',
    修改当前设置会重启语音服务并中断当前所有通话: 'Changing will restart the voice service and interrupt all current calls',
    最大通话时长秒: 'Maximum call duration (s)',
    十秒内最大注册尝试次数: 'Maximum registration attempts within 10 seconds',
    锁定时长秒: 'Lock duration（s）',
    起始值: 'Starting Value',
    结束值: 'End Value',
    RTP地址: 'RTP Ip',
    SIP地址: 'SIP Ip',
    RTP端口范围: 'RTP port range',
    SIP对接端口: 'SIP docking port',
    SIP认证端口: 'SIP authentication port',
    SIP协议内网IP: 'SIP protocol intranet IP',
    SIP协议公网IP: 'SIP protocol external IP',
    至: 'To',
    查看锁定设备: 'View locked devices',
    语音服务重启: 'Voice service restart',
    语音服务重启完成: 'Voice service restart completed',
    请选择模式: 'Please select a mode',
    每秒建立呼叫数量上限: 'Maximum number of calls established per second',
    手动指定: 'Manually',
    自动获取: 'Automatic',

    查看锁定地址: 'View locked address',
    锁定地址列表: 'Lock Address List',
    锁定地址明细: 'Lock Address Details',
    你确定要清空数据吗: 'Are you sure you want to clear the data?',
    清空成功: 'Clear successfully',

    存储管理: 'Storage Management',
    数据设置: 'Data Settings',
    每月一号凌晨2点配置自动生效: 'Automatically effective at 2am on the 1st of each month',
    任务查看: 'Task view',
    话单保存时间月: 'Cdr save time (Month)',
    录音保存时间月: 'Recording save time (Month)',
    话单保存时间: 'Cdr save time',
    录音保存时间: 'Recording save time',
    话单保存时间生效: 'Cdr save time takes effect',
    录音保存时间生效: 'Recording save time takes effect',
    任务清单: 'Task List',
    任务状态: 'Task status',
    执行时间: 'Execution time',
    任务类型: 'Task type',
    当前规则: 'Current rule',
    个月: ' months',
    已执行: 'executed',
    未执行: 'unexecuted',
    新增任务: 'Add Task',
    请选择任务类型: 'Please select a task type',
    请定义任务时间: 'Please define the task time',
    限制为当月内: 'Restricted to within the current month',
    至少为当前时间的10分钟以后: 'At least 10 minutes from the current time',

    日志设置: 'Log Settings',
    时间范围: 'time frame',
    录音保存时间小于等于话单保存时间: 'Recording save time is less than or equal to the ticket save time',
    FreeSWITCH单个文件大小: 'FreeSWITCH single file size (M)',
    FreeSWITCH文件个数: 'FreeSWITCH number of files',
    Core最大存储空间: 'Core maximum storage space (M)',
    Core保存天数: 'Core save days',

    会议2: 'Meeting',
    会议管理: 'Meeting Management',
    全部会议: 'All Meetings',
    预定义会议: 'Pre Defined Meeting',
    立即会议: 'Immediately Meeting',
    全部成员: 'All Members',
    会议名称或号码: 'Name Or Number',
    会议控制: 'Meet Control',
    进行中: 'Started',
    未开始: 'Not Started',
    会议成员: 'Members',
    已入会: 'Already joined',
    未入会: 'Not joined',
    全员静音: 'All Muted',
    解除全员静音: 'All UnMuted',
    加入会议: 'Join',
    结束会议: 'Stop',
    开始会议: 'Start',
    静音: 'Mute',
    邀请: 'Invite',
    在线: 'OnLine',
    离线: 'OffLine',
    添加预定义会议: 'Add predefined meetings',
    已选择: 'Selected',
    请选择要删除的会议: 'Please select the meeting to delete',
    你确定要删除该会议2吗: 'Are you sure you want to delete this meeting?',
    请选择要删除的联系人: 'Please select the contact to delete',
    你确定要删除该联系人吗: 'Are you sure you want to delete this contact?',
    主持人: 'Compere',
    会议名称: 'Name',
    会议号码: 'Number',
    备注信息: 'Remark',
    请选择主持人: 'Please select the compere',
    请选择参会成员: 'Please select the attending members',
    号码只允许添加数字: 'Number only allows adding digits',
    联系人新增: 'Add new contact person',
    联系人编辑: 'Edit contact person',
    未分配分机: 'Unallocated extension',
    自建联系人: 'Self built contacts',
    预定义: 'Predefine',
    自定义: 'Self define',
    即时: 'Instant',
    邀请成员: 'Invite members',
    联系人: 'Contacts',
    请选择号码: 'Please select a number',
    请选择成员: 'Please select members',
    添加成员成功: 'Successfully added member',
    移除成员成功: 'Successfully removed member',
    操作成功: 'Operation successful',
    开启成功: 'Opened successfully ',
    挂断成功: 'Hanging up successfully',
    会议未开始: 'Meeting has not started yet',
    会议已结束: 'The meeting has ended',
}
export default en
