import {createI18n} from 'vue-i18n'
// 引入各个语言配置文件
import zh from './zh' //中文
import en from './en'//英文

// 创建vue-i18n实例 i18n
const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    // 设置语言
    locale: localStorage.getItem('locale') || 'zh', // 语言标识
    // 添加多语言（每一个语言标识对应一个语言文件）
    messages: {
        zh,//中文
        en,//英文
    }
})

export default i18n