<template>
  <div class="nav-wrap">
    <el-menu default-active="1-4-1" class="el-menu-vertical-demo" :collapse="isCollapse"
      style="background-color: transparent;border:none;" router text-color="#000" active-text-color="#FE8A7D">
      <template v-for="item in tabList">
        <el-submenu v-if="item.children && item.children.length > 0" :key="item.path" :index="item.path">
          <template #title>
            <i :class="item.meta.icon"></i>
            <span>{{$t(item.meta.title)}}</span>
          </template>
          <template v-for="subItem in item.children">
            <el-submenu :key="subItem.path" :index="subItem.path"
              v-if="subItem.children && subItem.children.length > 0">
              <template #title>
                <i :class="subItem.meta.icon"></i>
                <span>{{$t(subItem.meta.title)}}</span>
              </template>
              <sub-item :chil="subItem.children" />
            </el-submenu>
            <template v-else>
              <el-menu-item :index="subItem.path" :key="subItem.path">
                <template v-if="!subItem.meta.isLink || (subItem.meta.isLink && subItem.meta.isIframe)">
                  <i :class="subItem.meta.icon"></i>
                  <span>{{$t(subItem.meta.title)}}</span>
                </template>
                <template v-else>
                  <a :href="subItem.meta.isLink" target="_blank" rel="opener">
                    <i :class="subItem.meta.icon"></i>
                    {{$t(subItem.meta.title)}}
                  </a>
                </template>
              </el-menu-item>
            </template>
          </template>

        </el-submenu>
        <template v-else>
          <el-menu-item :index="item.path" :key="item.path">
            <i :class="item.meta.icon"></i>
            <template #title v-if="!item.meta.isLink || (item.meta.isLink && item.meta.isIframe)">
              <span>{{ $t(item.meta.title) }}</span>
            </template>
            <template #title v-else>
              <a :href="item.meta.isLink" target="_blank" rel="opener">{{ $t(item.meta.title) }}</a></template>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>
<script>
  import {
    computed,
  } from 'vue'
  import router from '../../../router/index'
  import {
    useStore
  } from 'vuex'
  export default ({
    name: 'navMenu',
    setup() {
      const store = useStore()
      const tabList = router.options.routes[1].children
      //computed监听
      // 监听屏幕宽度，控制这个 isCollapse 就好了
      const isCollapse = computed(() => store.state.isCollapse);
      return {
        isCollapse,
        tabList,
      }
    }
  });
</script>
<style scoped>
  @import "../../../theme/home/home.scss";
  
.fa {
  font-size: 18px !important;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  width: 24px;
  text-align: center;
}
</style>