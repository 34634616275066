import axios from 'axios';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css'
import {
    ElMessageBox,
    ElMessage,
} from 'element-plus';
import router from '../router';

const service = axios.create({
    // baseURL: 'http://123.56.249.254:8079', //测试
    // baseURL: 'http://ipapi.cc/', //本地
    baseURL: '/index.php/', //生产
    timeout: 10000,
    headers: {
        ["Content-Type"]: "application/json;charset=UTF-8"
    }
});
// axios.defaults.headers["Content-Type"] = "application/json;charset=UTF-8"
// multipart/form-data;chart-set:UTF-8


service.interceptors.request.use(config => {
    // 在发送请求之前做些什么
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    const msToken = sessionStorage.getItem("msToken");
    if (msToken && !isToken) {
        config.headers['Authorization'] = 'Bearer ' + msToken // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    // config.headers.token='123123123'
    NProgress.start();
    return config;
}, error => {
    // 对请求错误做些什么
    return Promise.reject(error);
});

let judge = false;
// 添加响应拦截器
service.interceptors.response.use(response => {
    // 对响应数据做点什么
    if (response.data.code === '401' && !judge) {
        ElMessageBox.alert('你已被登出，请重新登录', '确认登出', {
            confirmButtonText: '确认'
        }).then(() => {
            router.push('/');
            setTimeout(() => {
                ElMessage.success('安全退出成功！');
            }, 300);
            judge = false;
        }).catch(() => {
            judge = false;
        });
        judge = true;
    }
    NProgress.done();
    // let data = response.data;
    return response.data;
}, error => {
    // 对响应错误做点什么
    return Promise.reject(error);

});

export default service
