<template>
  <el-form :model="installData" label-width="60px" label-position="top" ref="validateFrom" :rules="rules"
           @keyup.enter="handleSub">
    <el-form-item :label="$t('安装类型')" prop="dbhost">
      <el-radio-group v-model="installData.type">
        <el-radio label="0">{{ $t('初始化') }}</el-radio>
        <el-radio label="1">{{ $t('更新') }}</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item :label="$t('数据库')" prop="dbhost">
      <el-input :placeholder="$t('数据库地址')" v-model="installData.dbhost" clearable>
      </el-input>
    </el-form-item>
    <el-form-item prop="dbname">
      <el-input :placeholder="$t('数据库用户名')" autofocus v-model="installData.dbname" class="iconr" clearable
      >
      </el-input>
    </el-form-item>
    <el-form-item prop="dbpwd">
      <el-input :placeholder="$t('数据库密码')" v-model="installData.dbpwd" class="iconr" show-password
      >
      </el-input>
    </el-form-item>
    <el-form-item :label="$t('REDIS')" prop="redisHost">
      <el-input :placeholder="$t('连接地址')" v-model="installData.redisHost" clearable>
      </el-input>
    </el-form-item>
    <el-form-item prop="redisPwd">
      <el-input :placeholder="$t('密码')" v-model="installData.redisPwd" class="iconr" show-password
      >
      </el-input>
    </el-form-item>
    <el-form-item :label="$t('WEBSOCKET')" prop="webSocketAddr">
      <el-input :placeholder="$t('连接地址')" v-model="installData.webSocketAddr" clearable>
      </el-input>
    </el-form-item>
    <el-form-item :label="$t('管理员')" prop="name" v-if="installData.type==0" style="margin-bottom: 25px;">
      <el-input :placeholder="$t('管理员用户名')" v-model="installData.name" class="iconr" clearable disabled
      >
      </el-input>
    </el-form-item>
    <el-form-item prop="pwd" v-if="installData.type==0" style="margin-bottom: 25px;">
      <el-input :placeholder="$t('管理员密码')" v-model="installData.pwd" class="iconr" show-password>
      </el-input>
    </el-form-item>
    <el-form-item prop="rppwd" v-if="installData.type==0">
      <el-input :placeholder="$t('管理员确认密码')" v-model="installData.rppwd" class="iconr" show-password>
      </el-input>
    </el-form-item>
    <el-form-item>
      <el-button @click="subFun" class="login-sub-btn" type="primary" round>
        <span>{{ installData.type == 0 ? $t('安装') : $t('更新') }}</span>
      </el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import {
  reactive,
  ref,
  toRefs, computed,
} from 'vue'
import {useI18n} from "vue-i18n";
import {
  ElMessage
} from 'element-plus'
import {hideLoading, showLoading} from "@/http/loading";
import {
  installSet, installStart
} from '@/http/api.js'
import {
  useStore
} from 'vuex'
import {
  // ElMessageBox,
  ElNotification
} from 'element-plus';

export default ({
  name: 'install',
  setup() {
    const store        = useStore()
    const {t}          = useI18n()
    let installData    = reactive({
      type: '0',
      dbhost: 'ippbx-db',
      dbname: 'freecti',
      dbpwd: '',
      redisHost: 'ippbx-redis',
      redisPwd: '',
      webSocketAddr: 'ws://',
      name: 'admin',
      pwd: '',
      rppwd: '',
    })
    const validateFrom = ref()
    const rules        = computed(() => {
      const rule = {
        type: [{
          required: true,
          message: t("请选择安装类型"),
          trigger: "blur"
        }],
        dbhost: [{
          required: true,
          message: t("请输入数据库地址"),
          trigger: "blur"
        }],
        dbname: [{
          required: true,
          message: t("请输入数据库用户名"),
          trigger: "blur"
        }],
        dbpwd: [{
          required: true,
          message: t("请输入数据库密码"),
          trigger: "blur"
        }],
        redisHost: [{
          required: true,
          message: t("请输入Redis地址"),
          trigger: "blur"
        }],
        redisPwd: [{
          required: true,
          message: t("请输入Redis密码"),
          trigger: "blur"
        }],
        webSocketAddr: [{
          required: true,
          message: t("请输入WebSocket地址"),
          trigger: "blur"
        }],
        name: [{
          required: true,
          message: t("请输入用户名"),
          trigger: "blur"
        }],
        pwd: [{
          required: true,
          message: t("请输入密码"),
          trigger: "blur"
        }, {
          pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,12}$/,
          message: t('八到12位包含大小写字母和数字'),
          trigger: 'blur',
        }]
      }
      return rule;
    })

    const handleSub = (e) => {
      var keyCode = window.event ? e.keyCode : e.which;
      if (keyCode == 13) {
        subFun();
      }
    }

    // eslint-disable-next-line no-unused-vars
    var wss_res          = false;
    var wss_con          = null;
    const checkWebSocket = (url, cb) => {
      if (cb) {
        cb();
      }
      wss_con         = new WebSocket(url);
      wss_con.onopen  = event => {
        wss_res = true;
        console.log('连接Socket', event);
      };
      wss_con.onerror = event => {
        wss_res = false;
        console.log('连接Socket错误', event);
      };
    }

    let subFun = () => {
      var settingTxt = t('配置文件已写入即将开始安装');
      var installTxt = t('安装成功即将跳转至登录页');
      if (installData.type == 1) {
        settingTxt = t('配置文件已写入即将开始更新');
        installTxt = t('更新成功即将跳转至登录页');

        validateFrom.value.rules.dbpwd = null;
      } else {
        validateFrom.value.rules.dbpwd = [{
          required: true,
          message: t("请输入数据库密码"),
          trigger: "blur"
        }];
      }
      validateFrom.value.validate((valid) => {
        if (valid) {
          const params = {
            type: installData.type,
            dbhost: installData.dbhost,
            dbname: installData.dbname,
            dbpwd: installData.dbpwd,
            redisHost: installData.redisHost,
            redisPwd: installData.redisPwd,
            webSocketAddr: installData.webSocketAddr,
            name: installData.name,
            pwd: installData.pwd,
            rppwd: installData.rppwd,
          }
          showLoading();
          checkWebSocket(installData.webSocketAddr, () => {
            setTimeout(function () {
              if (wss_con) {
                wss_con.close();
                wss_con = null;
              }
              if (!wss_res) {
                hideLoading();
                ElNotification({
                  title: t('失败'),
                  message: t('WebSocket连接失败'),
                  type: 'error',
                  duration: 2000,
                });
                return;
              }
              installSet(params).then(res => {
                hideLoading();
                if (res.code == 200) {
                  ElMessage.success(settingTxt);
                  installStart(params).then(sRes => {
                    if (sRes.code == 200) {
                      ElMessage.success(installTxt);
                      setTimeout(function () {
                        store.commit('SET_INSTALL')
                      }, 2000);
                    } else {
                      ElNotification({
                        title: t('失败'),
                        message: sRes.msg,
                        type: 'error',
                        duration: 2000,
                      });
                    }
                  });
                } else {
                  ElNotification({
                    title: t('失败'),
                    message: res.msg,
                    type: 'error',
                    duration: 2000,
                  });
                }
              }).catch(err => {
                hideLoading();
                console.log('err:', err);
                ElNotification({
                  title: t('失败'),
                  message: t('请求错误'),
                  type: 'error',
                  duration: 2000,
                });

              })
            }, 3500)
          });
        }
      });
    }

    return {
      installData,
      subFun,
      handleSub,
      ...toRefs(installData),
      validateFrom,
      rules
    }
  },
})
</script>
<style scoped>
.iconr :deep(.el-input__icon) {
  font-style: normal !important;
}

.login-content-code {
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
}

.el-form-item {
  margin-bottom: 20px;
}

.login-content-code-img {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: #ffffff;
  border: 1px solid rgb(220, 223, 230);
  color: #333;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 5px;
  text-indent: 5px;
  text-align: center;
  cursor: pointer;
  transition: all ease 0.2s;
  border-radius: 4px;
  user-select: none;

  &:hover {
    border-color: #c0c4cc;
    transition: all ease 0.2s;
  }
}

.login-content-main .login-sub-btn {
  width: 100%;
  letter-spacing: 2px;
  font-weight: 300;
  margin-top: 10px;
}

.box-remember :deep(.el-form-item__content) {
  display: flex;
  justify-content: space-between;
}

.checkbox__inner:after {
  height: 14px;
  left: 7px;
  top: 0;
}

</style>
