import {
    createRouter,
    createWebHashHistory
} from 'vue-router'
import Home from '../views/home/index.vue'
import Login from '../views/login/index.vue'

const routes = [
    {
        path: '/',
        name: 'Login',
        meta: {
            isShow: false
        },
        component: Login
    },
    {
        path: '/Home',
        name: 'Home',
        component: Home,
        // component: () => import('../views/home/index.vue'),
        meta: {
            isShow: false,
            title: '首页',
        },
        children: [
            {
                path: '/Home',
                name: 'Home',
                meta: {
                    isShow: true,
                    title: '首页',
                    icon: 'iconfont icon-shouye-tianchong',
                    isLink: '',
                    isHide: false,
                    isKeepAlive: true,
                    isAffix: true,
                    isIframe: false,

                },
                component: () => import('../views/home/home.vue'),
            },
            {
                path: '/extension',
                name: 'Extension',
                meta: {
                    isShow: true,
                    title: '分机设置',
                    icon: 'iconfont icon-dianhuatianchong',
                    isLink: '',
                    isHide: false,
                    isKeepAlive: true,
                    isAffix: true,
                    isIframe: false,
                },
                component: () => import('../views/extension/index.vue')
                // component: Extension,
            },
            {
                path: '/relay',
                name: 'Relay',
                meta: {
                    isShow: true,
                    title: '中继设置',
                    icon: 'fa fa-sliders',
                    isLink: '',
                    isHide: false,
                    isKeepAlive: true,
                    isAffix: true,
                    isIframe: false,
                },
                component: () => import( /* webpackChunkName: "login" */ '../views/relay/index.vue')
            },
            {
                path: '/control',
                name: 'Control',
                redirect: '/control',
                meta: {
                    isShow: false,
                    title: '呼叫控制',
                    icon: 'iconfont icon-ic_zhongji',
                    isLink: '',
                    isHide: false,
                    isKeepAlive: true,
                    isAffix: true,
                    isIframe: false,
                },
                component: () => import('../views/routerView/parent.vue'),
                children: [
                    {
                        path: '/control/incomingCall',
                        name: 'IncomingCall',
                        meta: {
                            isShow: true,
                            title: '呼入路由',
                            icon: 'fa fa-arrow-down',
                            isLink: '',
                            isHide: false,
                            isKeepAlive: true,
                            isAffix: true,
                            isIframe: false,
                        },
                        component: () => import( /* webpackChunkName: "login" */ '../views/incomingCall/index.vue')
                    },
                    {
                        path: '/control/exhale',
                        name: 'Exhale',
                        meta: {
                            isShow: true,
                            title: '呼出路由',
                            icon: 'fa fa-arrow-up',
                            isLink: '',
                            isHide: false,
                            isKeepAlive: true,
                            isAffix: true,
                            isIframe: false,
                        },
                        component: () => import( /* webpackChunkName: "login" */ '../views/exhale/index.vue')
                    },
                    {
                        path: '/control/time',
                        name: 'Time',
                        meta: {
                            isShow: true,
                            title: '时间条件',
                            icon: 'fa fa-clock-o',
                            isLink: '',
                            isHide: false,
                            isKeepAlive: true,
                            isAffix: true,
                            isIframe: false,
                        },
                        component: () => import( /* webpackChunkName: "login" */ '../views/setUp/ConfunctionSet/timeConditions.vue')
                    },
                ],
            },
            {
                path: '/group',
                name: 'Group',
                redirect: '/group/grouping',
                meta: {
                    isShow: false,
                    title: '呼叫功能',
                    icon: 'fa fa-server',
                    isLink: '',
                    isHide: false,
                    isKeepAlive: true,
                    isAffix: true,
                    isIframe: false,
                },
                component: () => import('../views/routerView/parent.vue'),
                children: [
                    {
                        path: '/switchboard',
                        name: 'Switchboard',
                        meta: {
                            isShow: true,
                            title: 'IVR总机',
                            icon: 'fa fa-tty',
                            isLink: '',
                            isHide: false,
                            isKeepAlive: true,
                            isAffix: true,
                            isIframe: false,
                        },
                        component: () => import( /* webpackChunkName: "login" */ '../views/switchboard/index.vue')
                    },
                    {
                        path: '/group/grouping',
                        name: 'Grouping',
                        meta: {
                            isShow: true,
                            title: '分组设置',
                            icon: 'iconfont icon-navicon-rqfz',
                            isLink: '',
                            isHide: false,
                            isKeepAlive: true,
                            isAffix: true,
                            isIframe: false,
                        },
                        component: () => import('../views/group/grouping.vue')
                    },
                    {
                        path: '/group/queue',
                        name: 'Queue',
                        meta: {
                            isShow: true,
                            title: '队列设置',
                            icon: 'iconfont icon-danseshixintubiao-',
                            isLink: '',
                            isHide: false,
                            isKeepAlive: true,
                            isAffix: true,
                            isIframe: false,
                        },
                        component: () => import('../views/group/queue.vue')
                    },
                    {
                        path: '/group/funtioncode',
                        name: 'FunctionCode',
                        meta: {
                            isShow: true,
                            title: '功能码',
                            icon: 'fa fa-code-fork',
                            isLink: '',
                            isHide: false,
                            isKeepAlive: true,
                            isAffix: true,
                            isIframe: false,
                        },
                        component: () => import('../views/setUp/ConfunctionSet/functionCode.vue')
                    },
                    {
                        path: '/group/conference',
                        name: 'Conference',
                        meta: {
                            isShow: true,
                            title: '电话会议',
                            icon: 'fa fa-gears',
                            isLink: '',
                            isHide: false,
                            isKeepAlive: true,
                            isAffix: true,
                            isIframe: false,
                        },
                        component: () => import('../views/setUp/ConfunctionSet/conference.vue')
                    },
                ],
            },
            {
                path: '/meet',
                name: 'Meet',
                redirect: '/meet',
                meta: {
                    isShow: false,
                    title: '会议管理',
                    icon: 'fa fa-users',
                    isLink: '',
                    isHide: false,
                    isKeepAlive: true,
                    isAffix: true,
                    isIframe: false,
                },
                component: () => import('../views/routerView/parent.vue'),
                children: [
                    {
                        path: '/meet/member',
                        name: 'confMember',
                        meta: {
                            isShow: true,
                            title: '全部成员',
                            icon: 'fa fa-th',
                            isLink: '',
                            isHide: false,
                            isKeepAlive: true,
                            isAffix: true,
                            isIframe: false,
                        },
                        component: () => import( /* webpackChunkName: "login" */ '../views/meet/member.vue')
                    },
                    {
                        path: '/meet/reMode',
                        name: 'confModeRe',
                        meta: {
                            isShow: true,
                            title: '全部会议',
                            icon: 'fa fa-tags',
                            isLink: '',
                            isHide: false,
                            isKeepAlive: true,
                            isAffix: true,
                            isIframe: false,
                        },
                        component: () => import( /* webpackChunkName: "login" */ '../views/meet/modeRe.vue')
                    },
                ],
            },
            {
                path: '/media',
                name: 'Media',
                meta: {
                    isShow: true,
                    title: '提示音',
                    icon: 'fa fa-music',
                    isLink: '',
                    isHide: false,
                    isKeepAlive: true,
                    isAffix: true,
                    isIframe: false,
                },
                component: () => import('../views/setUp/ConfunctionSet/promptTone.vue')
            },
            {
                path: '/recordsCenter',
                name: 'recordsCenter',
                redirect: '/recordsCenter/recordings',
                meta: {
                    isShow: false,
                    title: '数据中心',
                    icon: 'fa fa-database',
                    isLink: '',
                    isHide: false,
                    isKeepAlive: true,
                    isAffix: true,
                    isIframe: false,
                },
                component: () => import('../views/routerView/parent.vue'),
                children: [
                    {
                        path: '/group/department',
                        name: 'Department',
                        meta: {
                            isShow: true,
                            title: '部门设置',
                            icon: 'iconfont icon-bumen',
                            isLink: '',
                            isHide: false,
                            isKeepAlive: true,
                            isAffix: true,
                            isIframe: false,
                        },
                        component: () => import('../views/group/department.vue')
                    },
                    // {
                    //   path: '/recordsCenter/recordings',
                    //   name: 'Recordings',
                    //   meta: {
                    //     isShow: true,
                    //     title: '录音记录',
                    //     icon: 'el-icon-phone',
                    //     isLink: '',
                    //     isHide: false,
                    //     isKeepAlive: true,
                    //     isAffix: true,
                    //     isIframe: false,
                    //   },
                    //   component: () => import('../views/recordsCenter/components/recordings.vue')
                    // },
                    {
                        path: '/recordsCenter/callRecords',
                        name: 'CallRecords',
                        meta: {
                            isShow: true,
                            title: '通话记录',
                            icon: 'iconfont icon-tonghuajilu',
                            isLink: '',
                            isHide: false,
                            isKeepAlive: true,
                            isAffix: true,
                            isIframe: false,
                        },
                        component: () => import('../views/recordsCenter/callRecords.vue')
                    },
                    {
                        path: '/recordsCenter/callTimeRecorder',
                        name: 'CallTimeRecorder',
                        meta: {
                            isShow: true,
                            title: '通话统计',
                            icon: 'iconfont icon-shujutongji',
                            isLink: '',
                            isHide: false,
                            isKeepAlive: true,
                            isAffix: true,
                            isIframe: false,
                        },
                        component: () => import('../views/recordsCenter/callTimeRecorder.vue')
                    },
                ]
            },
            {
                path: '/setUp',
                name: 'SetUp',
                redirect: '/setUp/safeSet',
                meta: {
                    isShow: false,
                    title: '系统设置',
                    icon: 'iconfont icon-shezhi',
                    isLink: '',
                    isHide: false,
                    isKeepAlive: true,
                    isAffix: true,
                    isIframe: false,
                },
                component: () => import( '../views/routerView/parent.vue'),
                children: [
                    {
                        path: '/setUp/safeSet',
                        name: 'SafeSet',
                        meta: {
                            isShow: true,
                            title: '安全设置',
                            icon: 'iconfont icon-7anquanshezhi18x20',
                            isLink: '',
                            isHide: false,
                            isKeepAlive: true,
                            isAffix: true,
                            isIframe: false,
                        },
                        component: () => import('../views/safety/safeConf.vue')
                    },
                    {
                        path: '/setUp/systemParam',
                        name: 'SystemParam',
                        meta: {
                            isShow: true,
                            title: '系统参数',
                            icon: 'fa fa-list-alt',
                            isLink: '',
                            isHide: false,
                            isKeepAlive: true,
                            isAffix: true,
                            isIframe: false,
                        },
                        component: () => import('../views/setUp/systemSetup.vue')
                    },
                    {
                        path: '/setUp/systemLog',
                        name: 'SystemLog',
                        meta: {
                            isShow: true,
                            title: '日志管理',
                            icon: 'fa fa-clipboard',
                            isLink: '',
                            isHide: false,
                            isKeepAlive: true,
                            isAffix: true,
                            isIframe: false,
                        },
                        component: () => import('../views/sysLog/index.vue')
                    },
                ]
            },
        ],
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
    if (to.path === '/') return next()
    // 获取token
    const tokenStr = window.sessionStorage.getItem('msToken')
    if (!tokenStr) return next('/')
    next()
})


export default router
export {routes}
